import styled from 'styled-components';

export const Rootcontainer = styled.div `
width:100%;

`
export const RootDiv=styled.div `
width: 100%;
display: table;
padding: 3px 27px;
background-color: #fbfbfb3d;
line-height: 2;
border-bottom: 1px solid whitesmoke;
`

export const RootTitle=styled.label `
float:left;    font-size: 21px;
font-weight: 600;margin-bottom: 0px;
`

export const RootBal=styled.label `
float:right;    font-size: 21px;
font-weight: 600;margin-bottom: 0px;
`

export const RootLeft=styled.div `
float: left;
`

export const RootRight = styled.div `
float:right;
`

export const RootValue = styled.label `
    color:#1bcd56;
    font-size: 21px;
    font-weight: 600;margin-bottom: 0px;
    `
    export const FooterUl = styled.ul ` 
    position: fixed;
    bottom: 0;
    background: #ffffff;
    list-style:none;
    width: 100%;
    `
    export const FooterLi = styled.li ` 
    display:inline-block;
    
    `
