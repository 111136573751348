import React from "react";
import Drawer from "react-drag-drawer";
import "../../screens/CustomerDetails/index.css";
import { FaSearch } from "react-icons/fa";
import { MdClose, MdAddCircle, MdTransferWithinAStation } from "react-icons/md";
import {
  Rootcontainer,
  SearchDiv,
  MainContainer,
  RootDiv,
  RootTitle,
  RootBal,
  RootRight,
  Amt,
  MoreDetails,
  RootLeft,
  RootValue,
  CustomerStatus,
  BtnGroupRight,
  STBGroup,
  STBDetails,
  CustomerStatusDiv,
  ActivPackage,
  ChannelBox,
  ChannelName,
  ChannelPrice,
  AddPackageHeading,
  SelectedPackage,
  SelectedDiv,
  BalanceDiv,
  CurrentBal,
  TotalBal,
  AvailableBal
} from "./styled";
import {
  FormControl,
  FormGroup,
  FormLabel,
  Container,
  ControlLabel,
  HelpBlock,
  Checkbox,
  Radio,
  Button,
  InputGroup,
  Form,
  Card,
  Row,
  Col
} from "react-bootstrap";
export default class AddPack extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: ""
    };
  }
  toggle = () => {
    let { toggle } = this.state;

    this.setState({ toggle: !toggle });
  };

  handleScroll(e) {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    console.log("at the not");
    if (bottom) {
      console.log("at the bottom");
      this.props.nextPageActivityLog(
        this.props.activityLogData,
        this.props.ActivityLogPageCount + 1
      );
    }
  }
  render() {
    if (this.props.isVisibleActivityLogDialog) {
      return (
        <Drawer
          open={this.props.isVisibleActivityLogDialog}
          onRequestClose={this.toggle}
          direction="left"
          className="popupwidth"
        >
          {/* <div>Hey Im inside a drawer!</div> */}

          <div
            style={{
              overflow: "auto",
              background: "#ffffff",
              display: "table"
            }}
          >
            <label style={{ fontSize: 15, fontWeight: "bold", color: "red" }}>
              {this.props.isEmptyErrorInAddPackDialog}
            </label>

            <AddPackageHeading>
              <MdTransferWithinAStation className="popup-icon" />
              Activity Details
            </AddPackageHeading>

            <div
              onScroll={e => this.handleScroll(e)}
              style={{ borderTop: "1px solid #f1f1f1", padding: 21 }}
            >
              <table className="table table-striped">
                <thead>
                  <th>Packages</th>
                  <th>Created</th>
                  <th>STB</th>
                  <th>Action</th>
                  <th>Message</th>
                  <th>Exception</th>
                  <th>Status</th>
                </thead>
                <tbody>
                  {this.props.activityLogData.map((data, i) => {
                    debugger;
                    console.log("all data", data.Products);
                    return (
                      <tr>
                        <td>{data.product.length ? data.product : "Null"}</td>
                        <td>{data.created}</td>
                        <td>{data.stb}</td>
                        <td>{data.action}</td>
                        <td>{data.message ? data.message : "Null"}</td>
                        <td>{data.exception}</td>
                        <td
                          style={{
                            color: data.status == "fail" ? "red" : "green"
                          }}
                        >
                          {data.status}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="footer-div">
              <Button
                variant="danger"
                className="pull-left mt-1 mr-3 ml-3"
                onClick={() => {
                  this.props.hindActivityLogDialog();
                }}
              >
                {" "}
                Close
              </Button>
            </div>
          </div>
        </Drawer>
      );
    } else {
      return null;
    }
  }
}
