import React from "react";
import Drawer from "react-drag-drawer";
import "./index.css";
import {
  Button,
  ButtonGroup,
  Container,
  ButtonToolbar,
  Jumbotron,
  Card
} from "react-bootstrap";
import Undraw from "react-undraw";
export default class DialogBoxs extends React.Component {
  render() {
    return (
      <div>
        <Drawer
          open={this.props.isShowLogOutWarning}
          onRequestClose={this.toggle}
          direction="left"
        >
          <Card>
            <Card.Body className="pl-3 pr-3">
              <div style={{ fontSize: 18 }}>
                Do you want{" "}
                <span style={{ color: "red", fontWeight: "bold" }}>
                  Log Out
                </span>{" "}
                ?
              </div>

              <Undraw className="undrawimg" name="monitor" />
              <Button
                style={{ backgroundColor: "red", borderColor: "red" }}
                className="mt-3 pull-right"
                onClick={() => {
                  this.props.hindLogOutDialog();
                }}
              >
                cancel
              </Button>
              <Button
                style={{
                  backgroundColor: "green",
                  borderColor: "green",
                  marginRight: 15
                }}
                className="mt-3 pull-right"
                onClick={() => {
                  this.props.logOut();
                }}
              >
                OK
              </Button>
            </Card.Body>
          </Card>
        </Drawer>
      </div>
    );
  }
}
