import React from 'react';
import Drawer from 'react-drag-drawer';
import '../../screens/CustomerDetails/index.css';
import {
    FaSearch
} from 'react-icons/fa';
import {
    Rootcontainer, SearchDiv, MainContainer, RootDiv, RootTitle, RootBal, RootRight, Amt, MoreDetails,
    RootLeft, RootValue, CustomerStatus, BtnGroupRight, STBGroup, STBDetails,
    CustomerStatusDiv, ActivPackage, ChannelBox, ChannelName, ChannelPrice, AddPackageHeading

} from './styled';
import { FormControl, FormGroup, FormLabel, Container, ControlLabel, HelpBlock, Checkbox, Radio, Button, InputGroup, Form, Card } from 'react-bootstrap';
export default class AddPack extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    toggle = () => {
        let { toggle } = this.state

        this.setState({ toggle: !toggle })
    }
    render() {
        if (this.props.isVisibleConformADD_Dialog) {
            return (



                <Drawer

                    open={this.props.isVisibleConformADD_Dialog}
                    onRequestClose={this.toggle}
                    direction='left' className="popup-width"
                >
                    {/* <div>Hey Im inside a drawer!</div> */}


                    <div style={{ width: 521, height: 451, overflow: "auto", background: "#ffffff" }}>
                        <AddPackageHeading>Confirmation !</AddPackageHeading>

                        <div style={{ height: 341, overflow: "auto", borderTop: "1px solid #f1f1f1" }}>
                            {this.props.conformADD_PackageList.map((data, i) => {
                                return (

                                    <Card key={i} className="package-details">

                                        <Card.Body>
                                            <div style={{ fontSize: 17, color: "#1aacc3" }}>{data.name}</div>
                                            <div style={{ display: "inline", color: "#d61818", float: "left", fontWeight: 600, fontSize: 17 }}>{'\u20B9'} {data.baseprice}</div>
                                            <Form.Group controlId="formBasicChecbox" style={{ float: "right", marginBottom: 0 }}>
                                                {/* <Form.Check 
    onClick={()=>this.props.toggleChangeInAddPackConfirmDialog(i)}
    type="checkbox" 
    label={data.toggleMode}  
    checked={data.toggleMode}/> */}
                                            </Form.Group>



                                        </Card.Body>
                                    </Card>

                                );
                            })}
                        </div>
                        <div>
                            <Button variant="danger" className="pull-left mt-1 mr-3 ml-3" onClick={() => { this.props.inVisibleConfirmPackDialog() }}> Cancel</Button >
                            <Button
                                onClick={() => { 
                                    this.props.calculateProDataPrice();
                                    this.props.showAddPackageWarningFunc() }} variant="success" className="pull-right mt-1 mr-3 ml-3"> ADD</Button>

                        </div>
                    </div>

                </Drawer>
            );
        } else {
            return null;
        }
    }
}