import React from 'react';
import {
    Rootcontainer, SearchDiv, MainContainer, RootDiv, RootTitle, RootBal, RootRight, Amt, MoreDetails,
    RootLeft, RootValue, CustomerStatus,
    CustomerStatusDiv

} from './styled';
import {
    MdPermIdentity,
    MdHome,
    MdFiberPin,
    MdPowerSettingsNew
} from 'react-icons/md';
import { Redirect } from 'react-router-dom';
import './index.css';
import logo from '../../assets/logo-3.png';
import Header from '../../GobelElements/Header';
import { Card, Row, Col, Button, ButtonToolbar } from 'react-bootstrap';
import {
    FaSearch,
    FaPlus,
    FaCircle
} from 'react-icons/fa';
import LoadingOverlay from 'react-loading-overlay';
import loadinggif from '../../assets/loding-gif.gif';
import BounceLoader from 'react-spinners/BounceLoader';
import API from '../../Api';
import Digital from 'react-activity/lib/Digital';
import Drawer from 'react-drag-drawer';
import TickIMG from '../../assets/tick.jpg';
import Loader from 'react-loader-spinner';
import { FaCheck } from "react-icons/fa";
import Undraw from 'react-undraw';
import DialogBoxes from './DialogBoxes';
import GeneralValues from '../../GobelElements/Values';
let lastScrollY = 0;
let ticking = false;
export default class index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lcoName: "",
            lcoBalance: "",
            lcocode: "",
            lcoID: localStorage.getItem("userID"),
            lcoPassword: localStorage.getItem("userPassword"),
            domainName: localStorage.getItem("domain"),
            isSpinnerVisible: true,
            renderTheSearchResult: [],
            searchText: props.searchQuery ? props.searchQuery : "",
            animating: false,
            customerList: [],
            totalCount: 0,
            pageCount: 1,
            custnoDeactive: '',
            isShowSearchList: false,
            isConfirmToDeactivate: false,
            toggle: false,
            isPaySucess: false,
            isPayError: false,
            isSendDeactivate: false,
            isShowLoader: false,
            isShowLogOutDialog: false,
            isShowExtendDialog: false,
            selectedCustomerNo: "",
            selectedAmount: 0,
            isShowResumeWarning: false,
            isShowMantainanceDialog: false,
            maintIMG: "",
            maintText: "",
            isShowLowBalanceDialog: false,
            lcoList: JSON.parse(localStorage.getItem("lcolist")),
            inStockListSearchText: "",
            inStockDataList: [],
            selectedAddress: "",
            isShowAddressDialog: false,


        }


    }

    nav = React.createRef();
    // componentWillReceiveProps(){
    //     const searchQuery= this.props.searchQuery;
    //     console.log("search",searchQuery);
    //     if(searchQuery){
    //         console.log("search1234",searchQuery);
    //         this.setState({searchText:searchQuery});
    //         this.getdata1();
    //     }

    // }
    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
        const searchQuery = this.props.searchQuery;

        if (searchQuery) {
            console.log("render search : ", this.state.searchText)
            console.log("search1234", searchQuery);
            this.setState({ searchText: searchQuery });
            console.log("dddssdsfd", this.state.searchText);
            this.getdata1();

        }

    }
    componentWillUnmount() {
        this.setState({ searchText: "" });
    }
    hindLowBalanceDialog() {
        this.setState({ isShowLowBalanceDialog: false });
    }
    componentWillMount() {
        this.getLCO_Details();

        // const searchQuery= this.props.searchQuery;
        // console.log("search",searchQuery);
        //  console.log("search",searchQuery);
        //     if(searchQuery){

        //        console.log("search1234",searchQuery);
        //        this.setState({searchText:searchQuery});
        //        console.log("dddssdsfd",this.state.searchText);
        //        this.getdata1();

        //    }
        window.removeEventListener('scroll', this.handleScroll);

        console.log("dddssdsfd", this.state.searchText);
    }
    // componentWillUnmount() {
    //     this.setState({ searchText: "" });
    // }

    hindExtendDialog() {
        this.setState({ isShowExtendDialog: false });
    }
    hindResumeWarning() {
        this.setState({ isShowResumeWarning: false })
    }
    moveToDetails(item, searchText) {
        console.log("searched key", searchText)
        this.props.moveToCustomerDetails("customerdetails", item, searchText);

    }
    getLCO_Details() {
        this.setState({ isSpinnerVisible: true })
        const { lcoID, lcoPassword, domainName } = this.state;
        API.getLOC_Details(lcoID, lcoPassword, "lcodetail", domainName)
            .then((response) => response.json())
            .then((responsejson) => {
                this.setState({
                    lcoName: responsejson.lconame, lcoBalance: responsejson.balance,
                    lcocode: responsejson.lcocode,
                    isShowMantainanceDialog: responsejson.maint,
                    maintText: responsejson.mainttext,
                    maintIMG: responsejson.maintimage
                });
                if (!this.state.searchText) {
                    this.setState({ isSpinnerVisible: false });
                }

            });

    }

    pressPayNow(userDetails) {
        if (userDetails.topay) {
            this.setState({ isSpinnerVisible: true });
            const customerNo = userDetails.Customernum;
            const { userName, userPassword } = this.state;
            API.payNowAPI(userName, userPassword, customerNo, "pay", this.state.domainName)
                .then((response) => response.json())
                .then((responsejson) => {
                    this.setState({ isSpinnerVisible: false, isPaySucess: true });
                    console.log("logn");
                    console.log("logn");


                    this.getdata1();
                    this.getLCO_Details();


                })
                .catch((err) => {
                    console.log("error", err);
                    this.setState({ isSpinnerVisible: false, isPayError: false });

                });
        }
    }
    nextPageData(data, pageNo) {
        let lengthOfACustomerList = this.state.customerList.length;
        console.log("lenght of the list", this.state.totalCount, lengthOfACustomerList);
        debugger;
        if (lengthOfACustomerList < this.state.totalCount) {
            // console.log("pageCount", pageNo);
            // this.setState({ animating: true });
            this.setState({ isShowLoader: true })
            const { lcoID, lcoPassword, domainName, searchText } = this.state;
            API.searchAPI(lcoID, lcoPassword, "search", searchText, pageNo, domainName)

                .then((response) => response.json())
                .then((responsejson) => {
                    if (responsejson.count) {
                        console.log("SearchResponse nextPageData", responsejson);

                        let oldData = data;
                        console.log("oldData", oldData);
                        let newData = oldData.concat(responsejson.content);
                        let count = this.state.pageCount + 1;
                        console.log("newData", newData);
                        debugger;

                        this.setState({ animating: false, isShowSearchList: true, customerList: newData, pageCount: count, isShowLoader: false });
                        // console.log("data", this.state.customerList);
                        // if (newData.length < responsejson.count) {

                        //     this.nextPageData(this.state.customerList, (this.state.pageCount + 1));
                        // }
                    }
                })
                .catch((err) => {

                    console.log("error", err);
                    debugger;

                    this.setState({ isSpinnerVisible: false });
                    // break


                });
        }
    }
    // getdataPage3(data) {
    //     const { lcoID, lcoPassword, domainName, searchText } = this.state;
    //     API.searchAPI(lcoID, lcoPassword, "search", searchText, 3, domainName)
    //         .then((response) => response.json())
    //         .then((responsejson) => {
    //             // console.log("SearchResponse", responsejson);
    //             let oldData = data;
    //             let newData = oldData.concat(responsejson.content);
    //             // if (newData.length < responsejson.count) {

    //             // this.nextPageData(this.state.customerList, (this.state.pageCount + 1));
    //             this.setState({ isSpinnerVisible: false, isShowSearchList: true, customerList: newData });
    //             // } else {
    //             //     this.setState({ isSpinnerVisible: false, isShowSearchList: true, customerList: newData });
    //             // }




    //         })
    //         .catch((err) => {

    //             console.log("error", err);
    //             this.setState({ isSpinnerVisible: false });
    //             // break


    //         });

    // }
    // getdataPage2(data) {

    //     const { lcoID, lcoPassword, domainName, searchText } = this.state;
    //     API.searchAPI(lcoID, lcoPassword, "search", searchText, 2, domainName)

    //         .then((response) => response.json())
    //         .then((responsejson) => {
    //             // console.log("SearchResponse", responsejson);
    //             let oldData = data;
    //             let newData = oldData.concat(responsejson.content);
    //             if (newData.length < responsejson.count) {

    //                 this.getdataPage3(newData);
    //             } else {
    //                 this.setState({ isSpinnerVisible: false, isShowSearchList: true, customerList: newData });
    //             }
    //         })
    //         .catch((err) => {

    //             console.log("error", err);
    //             this.setState({ isSpinnerVisible: false });
    //             // break


    //         });

    // }
    getdata1() {
        console.log("currentactivate", this.state.searchText)
        this.setState({ isSpinnerVisible: true });
        let data = [];
        // if(this.state.initialPageCount <=this.state.totalPageCount){


        this.setState({ isSpinnerVisible: true });


        debugger;
        const { lcoID, lcoPassword, domainName, searchText } = this.state;
        debugger;
        API.searchAPI(lcoID, lcoPassword, "basicsearch", searchText, 1, domainName)

            .then((response) => response.json())
            .then((responsejson) => {
                console.log("SearchResponse", responsejson);
                debugger;
                if (responsejson.message != "no data") {
                    let page1Data = [];
                    page1Data = responsejson.content;
                    this.setState({ isSpinnerVisible: true });
                    console.log("count", page1Data.length);
                    this.setState({ totalCount: responsejson.count });
                    // if (page1Data.length < responsejson.count) {

                    //     debugger;
                    //     this.getdataPage2(page1Data);
                    // } else {
                    this.setState({ isSpinnerVisible: false, isShowSearchList: true, customerList: responsejson.content });
                    // }


                    //  this.setState({ isSpinnerVisible: false, isShowSearchList: true,customerList:responsejson.content });


                } else {
                    this.setState({ isSpinnerVisible: false, isShowSearchList: false });
                    // break
                }
            })
            .catch((err) => {

                console.log("error", err);
                this.setState({ isSpinnerVisible: false });
                // break


            });



    }
    pressPayNow(userDetails) {
        if (userDetails.topay != 0) {
            this.setState({ isSpinnerVisible: true });
            const customerNo = userDetails.Customernum;
            const { lcoID, lcoPassword } = this.state;
            API.payNowAPI(lcoID, lcoPassword, customerNo, "pay", this.state.domainName)
                .then((response) => response.json())
                .then((responsejson) => {
                    this.setState({ isSpinnerVisible: false, isVisiblePaymentDialog: true });
                    console.log("logn", responsejson);
                    console.log("logn");


                    this.getdata1();
                    this.getLCO_Details();


                })
                .catch((err) => {
                    console.log("error 222", err);
                    this.setState({ isSpinnerVisible: false, errorMessage: "Unable to Process !", isPayError: true });

                });
        }
    }
    activateCustomer(userDetails) {

        this.setState({ isSpinnerVisible: true });
        const customerNo = userDetails.Customernum;
        const { lcoID, lcoPassword } = this.state;
        API.payNowAPI(lcoID, lcoPassword, customerNo, "pay", this.state.domainName)
            .then((response) => response.json())
            .then((responsejson) => {
                this.setState({ isSpinnerVisible: false, isVisiblePaymentDialog: true });
                console.log("logn", responsejson);
                console.log("logn");


                this.getdata1();
                this.getLCO_Details();


            })
            .catch((err) => {
                console.log("error 222", err);
                this.setState({ isSpinnerVisible: false, errorMessage: "Unable to Process !", isPayError: true });

            });

    }
    disconnectCustomer(userDetails) {
        this.state.custnoDeactive = userDetails.Customernum
        this.setState({ isSpinnerVisible: false, isConfirmToDeactivate: true });



    }
    confirmToDeactivate() {
        // const customerNo = userDetails.Customernum;
        this.setState({ isSpinnerVisible: true });
        const { lcoID, lcoPassword } = this.state;
        API.disconnect(lcoID, lcoPassword, "disconnect", this.state.custnoDeactive, this.state.domainName)
            .then((response) => response.json())
            .then((responsejson) => {
                this.setState({ isSpinnerVisible: false, isSendDeactivate: true, isConfirmToDeactivate: false });
                console.log("logn", responsejson);
                console.log("logn");
            })
            .catch((err) => {
                console.log("error 222", err);
                this.setState({ isSpinnerVisible: false, errorMessage: "Unable to Process !", isPayError: true });

            });
    }
    isShowRefresh(process) {
        if (process) {
            return (
                <Button className="mt-1" style={{ width: "50%", marginLeft: "21%", padding: 0 }} variant="primary" size="lg" block onClick={() => { this.getdata1() }}>Refresh</Button>
            );
        }
    }
    deactivateOk() {
        this.setState({ isSendDeactivate: false });
        this.getdata1();
        this.getLCO_Details();
    }

    getExtendFunc(day) {
        var req = parseInt((this.state.selectedAmount / 30) * day);
        var bal = parseInt(this.state.lcoBalance);
        if (req < bal) {
            this.setState({ isSpinnerVisible: true });
            const { lcoID, lcoPassword, domainName, selectedCustomerNo } = this.state;
            API.extendAPI(lcoID, lcoPassword, selectedCustomerNo, day, domainName)
                .then((response) => response.json())
                .then((responsejson) => {


                    console.log("response", responsejson);
                    debugger;
                    this.getdata1();
                })
                .catch((err) => {
                    console.log("error 222", err);
                    this.setState({ isSpinnerVisible: false });

                });
        }

    }

    resumeCustomer() {
        this.setState({ isSpinnerVisible: true });

        const { lcoID, lcoPassword, domainName, selectedCustomerNo } = this.state;
        API.resumeAPI(lcoID, lcoPassword, selectedCustomerNo, domainName)
            .then((response) => response.json())
            .then((responsejson) => {
                console.log("response in suspend", responsejson);
                debugger;
                // this.setState({isSpinnerVisible:false});
                this.getdata1();
            })

    }


    isShowStarAndEndDate(data) {
        if (data.daily) {
            return (
                <div style={{ marginLeft: 15, display: "block" }}>
                    <label style={{ fontSize: 14 }}>Start Date :  <span style={{ color: "red" }}> {data.start}</span></label>
                    <label style={{ fontSize: 14 }}>    end Date :</label><span style={{ color: "red", fontSize: 14 }}> {data.end}</span>
                </div>
            );
        } else {
            return null;
        }
    }
    whichBtnShow(data) {

        var isAllow = false;
        try {
            if (data.stbs[0].pack.length) {
                isAllow = true;
                console.log("length", data.stbs[0].pack.length);
            } else {
                throw "too low";
            }
        } catch (error) {

        }

        if (!data.process) {
            if (data.connectionstatus == "Disconnected" && data.paid == "unpaid" && isAllow) {

                return (
                    <Button className="mr-1"

                        onClick={() => this.activateCustomer(data)}
                        variant="info" style={{

                            border: 0, fontSize: 13,
                            width: 150
                        }}>{"Activate"}</Button>
                )
            } else {



                if (data.connectionstatus === "Live") {
                    return (
                        <Button className="mr-1"

                            onClick={() => this.disconnectCustomer(data)}
                            variant="primary" style={{
                                background: "red",
                                border: 0,
                                fontSize: 13,
                                width: 150
                            }}>{"Deactivate"}</Button>
                    );
                } else {
                    if (isAllow) {
                        return (
                            <Button className="mr-1"

                                onClick={() => this.activateCustomer(data)}
                                variant="info" style={{
                                    backgroundColor: "#236467",
                                    border: 0,
                                    fontSize: 13,
                                    width: 150
                                }}>{"Activate"}</Button>
                        );
                    } else {
                        return null;
                    }
                }
            }
        } else {
            return null
        }
    }
    paynowOrAvtivate(data) {
        if (data.connectionstatus == "Disconnected" && data.paid == "unpaid") {
            return null
        } else {

            if (!data.daily) {
                return (
                    <Button className="mt-0"


                        onClick={() => {
                            if (parseInt(data.topay) < parseInt(this.state.lcoBalance)) {
                                this.pressPayNow(data);
                            } else {
                                this.setState({ isShowLowBalanceDialog: true })
                            }
                        }

                        }
                        variant="success" style={{
                            background: Number(data.topay).toFixed(2) == 0.00 ? "#81c784" : "#388e3c",
                            border: 0,
                            fontSize: 13,
                            width: 150,
                            cursor: data.topay === 0 ? "not-allowed" : "pointer",

                        }}>{data.process ? "Pending......" : "Pay Now"}</Button>
                );
            } else {
                if (data.connectionstatus == "Live") {
                    return (
                        <Button className="mt-0"

                            onClick={() => {
                                this.setState({
                                    isShowExtendDialog: true,
                                    selectedCustomerNo: data.Customernum,
                                    selectedAmount: data.baseprice
                                })
                            }}
                            variant="success" style={{
                                background: Number(data.topay).toFixed(2) == 0.00 ? "#81c784" : "#388e3c",
                                border: 0,

                                cursor: "pointer",
                                fontSize: 13,
                                width: 150
                            }}>{"Extend"}</Button>
                    );
                } else {
                    return null;
                }
            }
        }
    }
    whichBtnsShow(data) {
        if (data.suspend) {
            this.isShowPayNowBTN(data);
        }
    }
    isShowPayNowBTN(data) {
        if (data.stbs.length && !data.suspend) {
            return (
                <div style={{ display: "flex", justifyContent: "space-around" }}>
                    {this.whichBtnShow(data)}
                    {this.paynowOrAvtivate(data)}
                </div>
            );
        } else {
            return null;
        }
    }
    isSTBIsPresent(stbs) {
        if (stbs.length != 0) {
            return (
                <div>

                    <div style={{ marginLeft: 10, display: "inline" }}>{stbs.map((item, i) => {
                        return (

                            <label >
                                {item.SNo}, </label>
                        );

                    })}</div>
                </div>
            );
        } else {
            return <label>None</label>;
        }
    }
    isShowResumeBtn(data) {
        if (data.suspend) {
            return (
                <Button className="mt-0"

                    onClick={() => {
                        this.setState({
                            isShowResumeWarning: true,
                            selectedCustomerNo: data.Customernum,

                        })
                    }}
                    // variant="success" 
                    style={{ backgroundColor: "#582A72", borderColor: "#582A72", width: "98%" }}
                >{"Resume"}</Button>
            );
        } else {
            return null;
        }
    }
    renderTheData() {
        if (this.state.isShowSearchList) {

            const { customerList } = this.state;
            return (
                customerList.map((data, i) => {
                    return (

                        // <Col xs={12} sm={6} md={4} lg={3} className="mt-3 mb-3">
                        //     <Card key={i} className="stb-details">

                        //         <Card.Body>
                        //             <CustomerStatusDiv>
                        //                 <label style={{ color: data.paid === "paid" ? "#1cda3e" : "#ef4747", float: "left" }}>{data.paid}</label>
                        //                 <label>Customer Status: </label> <CustomerStatus
                        //                     style={{ color: data.connectionstatus === "Live" ? "rgb(120, 210, 45)" : "red" }}
                        //                 >{data.connectionstatus}</CustomerStatus></CustomerStatusDiv>
                        //             <Card.Title><MdPermIdentity /><label style={{ color: "rgb(138, 205, 255)" }}>{data.name}</label><Amt style={{ color: "red" }}>     {'\u20B9'}{data.topay} </Amt></Card.Title>
                        //             <Card.Title><MdHome /> {data.Address}</Card.Title>
                        //             <Card.Title>{this.isSTBIsPresent(data.stbs)}</Card.Title>

                        //         </Card.Body>
                        //         <Card.Footer>
                        //             {this.isShowPayNowBTN(data)}
                        //             {this.isShowRefresh(data.process)}
                        //             {/* <Button variant="primary" size="lg" block onClick={()=>{this.getdata1()}}>Refresh</Button> */}
                        //             <MoreDetails onClick={() => this.moveToDetails(data, this.state.searchText)}>
                        //                 More Details...
                        // </MoreDetails>
                        //         </Card.Footer>
                        //     </Card>
                        // </Col>



                        <Col xs={12} sm={6} md={4} lg={3} className="mt-3 mb-3">
                            <Card key={i} className="stb-details">

                                <Row style={{ padding: "11px", overflow: " auto", height: "200px" }}>
                                    <Col md={8} sm={6} style={{ color: "#05547c", fontWeight: "600" }}>
                                        {data.name}
                                    </Col>
                                    <Col md={4} sm={6}>
                                        <label className="align-pu" style={{ color: data.paid === "paid" ? "#1cda3e" : "#ef4747" }}>{data.paid}</label>
                                    </Col>
                                    <Col md={6} sm={6}>
                                        <label className="align-pu" style={{
                                            color: data.connectionstatus === "Live" ? "rgb(120, 210, 45)" : "red",
                                            fontSize: "18px"
                                        }}>{data.connectionstatus}</label>
                                    </Col>
                                    <Col md={6} sm={6} className="text-right"
                                        style={{
                                            marginLeft: "-25px",
                                            fontSize: " 28px",
                                            fontWeight: "600",
                                            color: "#ea2222"
                                        }}

                                    >
                                        {'\u20B9'}{data.topay}
                                    </Col>
                                    <Col md={12} sm={12} style={{ fontSize: "14px", color: "#05547c" }}>
                                        <MdHome /> {data.Address}
                                    </Col>
                                    {/* 
     <Col md={9} sm={6}>
     <MdHome /> {data.Address}
         </Col>
         <Col md={3} sm={6}>
     {'\u20B9'}{data.topay}
         </Col> */}
                                    {this.isShowStarAndEndDate(data)}
                                    <Col md={12} sm={12}>
                                        {this.isSTBIsPresent(data.stbs)}
                                    </Col>
                                </Row>
                                <Card.Footer>
                                    {this.isShowPayNowBTN(data)}
                                    {this.isShowRefresh(data.process)}
                                    {this.isShowResumeBtn(data)}

                                    <MoreDetails onClick={() => this.moveToDetails(data, this.state.searchText)}>
                                        More Details...
                        </MoreDetails>
                                </Card.Footer>
                            </Card>
                        </Col>

                    );

                })

            );
        } else {
            return <label style={{ alignSelf: "center" }}>Empty List</label>
        }
    }
    renderTheData1() {
        if (this.state.isShowSearchList) {

            const { customerList } = this.state;
            return (


                <table className="table table-striped" style={{ marginTop: 10 }}>
                    <thead style={{backgroundColor:"red"}}>
                        <th>name</th>
                        <th>Address</th>
                        <th>Status</th>
                        <th>Connection</th>
                        <th>Amount </th>
                        <th>STB </th>
                        <th style={{ width: 200 }}>Process </th>
                        <th> More </th>



                    </thead>
                    <tbody className="total-records">
                        {customerList.map((data, i) => {
                            return (
                                <tr>
                                    <td >{data.name}</td>
                                    {/* <td >{data.Address}</td> */}
                                    <td style={{ fontSize: 12, maxLines: 2, maxheight: 40, overflow: "hidden" }}>
                                        <div style={{ height: 40, overflow: "hidden" }}>  {data.Address}</div>

                                        {data.Address.length > 24 ?
                                            <label onClick={() => {
                                                this.setState({ selectedAddress: data.Address, isShowAddressDialog: true })
                                            }} style={{ color: GeneralValues.mustard, textDecoration: "underline" }}>More</label>
                                            : null}
                                    </td>
                                    <td style={{ color: data.paid === "paid" ? "#1cda3e" : "#ef4747" }}>{data.paid}</td>
                                    <td style={{ color: data.connectionstatus === "Live" ? "rgb(120, 210, 45)" : "red" }}>{data.connectionstatus}</td>
                                    <td >{'\u20B9'}{data.topay}</td>
                                    <td >{this.isSTBIsPresent(data.stbs)}</td>
                                    <td style={{ width: 200 }}>
                                        {data.stbs.length ?
                                            <div>
                                                {this.isShowPayNowBTN(data)}
                                                {this.isShowRefresh(data.process)}
                                                {this.isShowResumeBtn(data)}
                                            </div>
                                            : <label>--</label>
                                        }

                                    </td>
                                    <td>
                                        <label
                                            onClick={() => { this.moveToDetails(data, this.state.searchText) }}
                                            style={{ color: GeneralValues.mustard, textDecoration: "underline" }}>More </label>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                // {customerList.map((data, i) => {
                //     return (





                // <Col xs={12} sm={12} md={12} lg={12} className="mt-3 mb-3">
                //     <Card key={i} className="stb-details">

                //         <Row style={{ padding: "11px", overflow: " auto", maxHeight: "150px" }}>
                //           <label  style={{marginLeft:15,fontSize:14 }}>Name :</label>  <label style={{ color: "#05547c", fontWeight: "600",marginLeft:10,fontSize:14 }}> {data.name}</label>
                //           <label  style={{marginLeft:15,fontSize:14 }}>Status :</label>    <label className="align-pu" style={{ color: data.paid === "paid" ? "#1cda3e" : "#ef4747",marginLeft:15,fontSize:14,marginTop:-1 }}>{data.paid}</label>
                //           <label  style={{marginLeft:15,fontSize:14 }}>Connection status :</label>   <label className="align-pu" style={{
                //                     color: data.connectionstatus === "Live" ? "rgb(120, 210, 45)" : "red",
                //                     marginLeft:15,fontSize:14,marginTop:-1  
                //                 }}>{data.connectionstatus}</label>
                //               <label  style={{marginLeft:15,marginTop:-1 }}>Amount :</label>   <label
                //                  style={{
                //                     marginLeft: "15px",
                //                     fontSize: " 14px",
                //                     fontWeight: "600",
                //                     color: "#ea2222",
                //                     marginRight:15,
                //                     marginTop:-1 

                //                 }}
                //                 >{'\u20B9'}{data.topay}</label>
                //             <label  style={{marginLeft:15,fontSize:14 }}>Address :</label> <label style={{fontSize:14}}>{data.Address}</label> 

                //             {this.isShowStarAndEndDate(data)}
                //             <Col md={12} sm={12}>
                //                 {this.isSTBIsPresent(data.stbs)}
                //             </Col>
                //         </Row>
                //         <Card.Footer>
                //             {this.isShowPayNowBTN(data)}
                //             {this.isShowRefresh(data.process)}
                //             {this.isShowResumeBtn(data)}

                //             <MoreDetails
                //             style={{maxHeight:10,fontSize:14,marginTop:0}}
                //             onClick={() => this.moveToDetails(data, this.state.searchText)}>
                //                 More Details...
                // </MoreDetails>
                //         </Card.Footer>
                //     </Card>
                // </Col>

                // );

                // })

            );
        } else {
            return <label>Empty List</label>
        }
    }
    toggle = () => {
        let { toggle } = this.state


        this.setState({ toggle: !toggle })
    }
    // handleScroll(e) {
    //     console.log("scorller", e.target.scrollHeight, e.target.scrollTop, e.target.clientHeight);
    //     const bottom = e.target.scrollHeight - (e.target.scrollTop) === e.target.clientHeight;
    //     console.log("lco", bottom);
    //     if (bottom) {

    //         this.nextPageData(this.state.customerList, (this.state.pageCount + 1));
    //     }
    // }

    logOutFunc() {
        if (this.state.isloggedOut) {
            return <Redirect to="/login" />
        } else {
            return null;
        }
    }
    logOut() {

        localStorage.removeItem("userID");
        const username = localStorage.getItem("userID");
        if (!username) {
            this.setState({ isloggedOut: true });
        }
    }
    stockListSearch(text) {

        var { lcoID, lcoPassword, domainName, inStockListSearchText } = this.state;
        if (text.length > 2) {
            this.setState({ inStockDataList: [], isShowInStockList: true, isShowStockLoader: true, noData: "" });

            API.stockListSearch(lcoID, lcoPassword, "liststock", text, 1, domainName)
                .then((response) => response.json())
                .then((responseJson) => {
                    console.log("response", responseJson);
                    if (responseJson.count) {


                        this.setState({ inStockDataList: responseJson.content, isShowInStockList: true, inStockCurrentPage: 1, totalCountInStock: responseJson.count, isShowStockLoader: false });
                        console.log("In stock list", this.state.inStockDataList);
                    } else {
                        this.setState({ inStockDataList: [], isShowStockLoader: false, noData: "No data found!" });
                    }
                });
        } else {
            this.setState({ isShowInStockList: false, isShowInStockList: false, isShowStockLoader: false, noData: "" });
        }
    }
    getSTBDetails(item) {
        console.log("item", item);
        if (item.Customer) {
            this.setState({ spinnerVisible: true });
            const { lcoID, lcoPassword, domainName, searchText } = this.state;
            API.STBSearch(lcoID, lcoPassword, "stbsearch", item.SNo, domainName)
                .then((response) => response.json())
                .then((responsejson) => {
                    console.log("response", responsejson);
                    if (responsejson.message == "no data") {
                        this.setState({ spinnerVisible: false });
                    } else {
                        localStorage.setItem("currentlco", item.Lcocode);
                        this.setState({ spinnerVisible: false });
                        this.props.moveToCustomerDetails("customerdetails", responsejson, this.state.searchText);
                    }
                });
        }
        else {
            this.setState({ isShowNodataDialog: true })
        }

    }
    handleScroll(e) {
        // console.log("scorller",e.target.scrollHeight,e.target.scrollTop,e.target.clientHeight);
        const bottom = e.target.scrollHeight - (e.target.scrollTop) === e.target.clientHeight;
        // console.log("lco",bottom);
        if (bottom) {
            debugger;
            this.nextPageData(this.state.customerList, (this.state.pageCount + 1));
        }
    }
    render() {
        return (
            //     <LoadingOverlay
            //     className="spinner"
            //     overlay={true}
            //     active={this.state.isSpinnerVisible}
            //     spinner={<BounceLoader className="spinnerContent" color="green" style={{marginLeft: 100,}}/>}

            //   >



            <Rootcontainer
                onScroll={(e) => this.handleScroll(e)}
            // ref={this.nav}
            //onScrollCapture={(e)=>{  this.nextPageData(this.state.customerList, (this.state.pageCount + 1))}}
            >

                <DialogBoxes
                    isShowExtendDialog={this.state.isShowExtendDialog}
                    hindExtendDialog={this.hindExtendDialog.bind(this)}
                    getExtendFunc={this.getExtendFunc.bind(this)}
                    selectedAmount={this.state.selectedAmount}
                    lcoBalance={this.state.lcoBalance}
                    isShowResumeWarning={this.state.isShowResumeWarning}
                    hindResumeWarning={this.hindResumeWarning.bind(this)}
                    resumeCustomer={this.resumeCustomer.bind(this)}
                    isShowMantainanceDialog={this.state.isShowMantainanceDialog}
                    maintIMG={this.state.maintIMG}
                    maintText={this.state.maintText}
                    getLOC_Details={this.getLCO_Details.bind(this)}
                    isShowLowBalanceDialog={this.state.isShowLowBalanceDialog}
                    hindLowBalanceDialog={this.hindLowBalanceDialog.bind(this)}
                />

                <Drawer

                    open={this.state.isPaySucess}
                    onRequestClose={this.toggle}
                    direction='left'

                >
                    {/* <div>Hey Im inside a drawer!</div> */}
                    <Card >

                        <Card.Body>
                            <div style={{ fontSize: 20, fontWeight: 'bold' }}>Pay Successfully</div>
                            <img src={TickIMG} style={{ width: 200, height: 200, marginTop: 20, marginLeft: 50 }}></img>
                            <button
                                onClick={() => this.setState({ isPaySucess: false })}
                                style={{ background: "green", color: 'white', padding: 15, marginTop: 20, textAlign: "right", display: "block", width: 60, height: 60, marginLeft: 256, borderRadius: 20 }}>OK</button>

                        </Card.Body>
                    </Card>

                </Drawer>
                <Drawer

                    open={this.state.isConfirmToDeactivate}
                    onRequestClose={this.toggle}
                    direction='left'

                >
                    {/* <div>Hey Im inside a drawer!</div> */}
                    <Card className="confirm-popup">

                        <Card.Body>
                            <div style={{ fontSize: 20, fontWeight: 'bold' }}> Deactivate</div>

                            <div className="mt-3"> <strong>Are Your Sure Want to Deactivate STB ?</strong> </div>

                            <Button variant="danger" className="mt-3 pull-right mr-2 ml-2"
                                onClick={() => {
                                    this.setState({ isConfirmToDeactivate: false })
                                }}
                            >Cancel</Button>
                            <Button variant="success" className="mt-3 pull-right mr-2 ml-2"
                                onClick={() => {
                                    this.confirmToDeactivate()
                                }}
                            >OK</Button>
                        </Card.Body>
                    </Card>

                </Drawer>
                <Drawer

                    open={this.state.isSendDeactivate}
                    onRequestClose={this.toggle}
                    direction='left'
                >
                    {/* <div>Hey Im inside a drawer!</div> */}
                    <Card >

                        <Card.Body>
                            <div style={{ fontSize: 20, fontWeight: 'bold' }}>Your request has been sent !</div>

                            <Button variant="success" className="pull-right mt-3"
                                onClick={() => { this.deactivateOk() }}
                            >OK</Button>

                        </Card.Body>
                    </Card>

                </Drawer>
                <Drawer

                    open={this.state.isPayError}
                    onRequestClose={this.toggle}
                    direction='left'
                >
                    {/* <div>Hey Im inside a drawer!</div> */}
                    <Card >

                        <Card.Body>
                            <div style={{ fontSize: 20, fontWeight: 'bold' }}>Please try again !</div>

                            <button
                                onClick={() => this.setState({ isPayError: false })}
                                style={{ background: "red", color: 'white', padding: 15, marginTop: 20, textAlign: "right", display: "block", width: 60, height: 60, marginLeft: 256, borderRadius: 20 }}>OK</button>

                        </Card.Body>
                    </Card>

                </Drawer>
                <LoadingOverlay

                    className={this.state.isSpinnerVisible ? "displaySpinner" : ""}
                    overlay={true}
                    active={this.state.isSpinnerVisible}
                    spinner={<img src={loadinggif} className="spinnerContent" color="green" style={{ marginLeft: 100, }} />}

                ></LoadingOverlay>

                <Drawer

                    open={this.state.isShowLogOutDialog}
                    onRequestClose={this.toggle}
                    direction='left'
                >

                    <Card >


                        <Card.Body className="pl-3 pr-3">
                            <div style={{ fontSize: 15, fontWeight: 'bold', }}>Do you want <span style={{ fontSize: "1.5em", color: "red" }}>Log out</span> ?</div>
                            <Undraw className="undrawimg" name="monitor" />
                            <Button
                                style={{ marginLeft: 20 }}
                                className="success" className="mt-3 pull-right"
                                onClick={() => {


                                    this.setState({ isShowLogOutDialog: false })

                                }}
                            >Cancel</Button>

                            <Button
                                style={{ backgroundColor: "red" }}
                                className="danger" className="mt-3 pull-right"
                                onClick={() => {

                                    // this.props.hindNodataDialog();
                                    this.logOut();

                                }}
                            >OK</Button>



                        </Card.Body>
                    </Card>

                </Drawer>
                <div className="header-div" style={{backgroundColor:GeneralValues.blueColor}}>

                    <h3 style={{ marginLeft: '105px',marginRight:"-40px",textTransform:"uppercase" }}>    Customers Search</h3>
                    <img
                        onClick={() => { this.props.moveToDashBoard() }}
                        src={GeneralValues.circlelogo} style={{ float: "left", marginLeft: " -190px" }} className="logo-img" alt="logo" />


                    <MdPowerSettingsNew onClick={() => { this.setState({ isShowLogOutDialog: true }) }} value="Logout" className="fontSize login" />
                    <input type="text" text="Search" placeholder="Global Search..."
                        onChange={(e) => {
                            this.setState({ inStockListSearchText: e.target.value })
                            this.stockListSearch(e.target.value);
                        }}

                        value={this.state.inStockListSearchText}
                        className="input-STB"
                        style={{ backgroundColor: "transparent", borderColor: "transparent", borderBottom: "2px solid white", color: "white", textAlign: "center", fontSize: 15, borderRight: "2px solid white", fontWeight: "bold", alignSelf: "end", marginLeft: "50%" }} />
                    <div style={{
                        position: "absolute",
                        display: this.state.isShowInStockList ? "block" : "none",
                        listStyle: "none",
                        backgroundColor: "white",
                        padding: 0,
                        boxShadow: "0px 1px 3px #e9b42a",
                        zIndex: 9,
                        width: "22vw",
                        top: 60,
                        overflow: "auto",
                        maxHeight: "30vh",
                        marginLeft: "68%"

                    }}
                        onScroll={(e) => this.handleScroll(e)}>
                        <label style={{ fontSize: 15, fontWeight: "bold", color: "rgb(26, 172, 195)", marginLeft: 10 }}>List:</label>
                        <div style={{ textAlign: "center", color: "gray" }}>{this.state.noData}</div>
                        <ul style={{ listStyle: "none", padding: 10 }}>

                            {this.state.inStockDataList.map((item, i) => {

                                return (


                                    <li className="search-list"
                                        onClick={() => { this.getSTBDetails(item) }}
                                        style={{ fontSize: 15, color: "black", lineHeight: 2, borderBottom: "1px solid rgba(26, 172, 195,0.5)", cursor: "pointer" }}>
                                        <div>
                                            <lable style={{ display: "block" }}>
                                                Name:
   {item.Customer ? item.Customer : <FaPlus style={{
                                                    background: " #0a3f65",
                                                    color: "white", width: "20px", height: "20px", padding: "2px", borderRadius: "10px", marginLeft: "8px"
                                                }} />}

                                                <FaCircle style={{
                                                    display: item.status ? "inline-block" : "none",
                                                    height: "12px", float: "right", marginTop: "8px",
                                                    color: item.status == "Live" ? "green" : "red",
                                                }} />


                                            </lable>

                                            <lable style={{ display: "block" }}>STB: {item.SNo}

                                                <div style={{
                                                    fontSize: "12px", display: item.paid ? "inline-block" : "none", float: "right", color: "white",
                                                    padding: "0px 7px", height: "25px",
                                                    backgroundColor: item.paid === "paid" ? "green" : "red",
                                                }}>{item.paid}  </div>
                                            </lable>

                                            <lable style={{ display: "block", fontSize: "12px" }}>Lco: {item.Lconame}

                                                <div style={{ fontSize: "12px", display: "inline-block", float: "right", color: "red" }}>({item.Lcocode})
  </div>
                                            </lable>
                                        </div>
                                    </li>

                                );
                            })}
                        </ul>

                        <Loader
                            visible={this.state.isShowStockLoader}
                            style={{ textAlign: "center", marginBottom: 10, }}
                            type="ThreeDots"
                            color="#00BFFF"
                            height="10"
                            width="20"
                        />
                    </div>
                </div>
                <RootDiv>
                    <RootLeft>
                        <RootTitle>
                            Name :
                    </RootTitle>

                        <RootValue
                            style={{ cursor: "pointer" }}
                            onClick={() => { this.props.onClickProfile() }} >
                            {this.state.lcoName}

                        </RootValue>
                        <div className="lcocode" >

                            (<span style={{ color: "black" }}>Lco code:</span> {this.state.lcocode})
                </div>
                    </RootLeft>

                    <RootRight>
                        <RootTitle>
                            Balance :
</RootTitle>

                        <RootValue>
                            {'\u20B9'} {this.state.lcoBalance}
                        </RootValue>

                    </RootRight>
                </RootDiv>
                <MainContainer>


                    <SearchDiv searchText="searchText" className="mt-2" style={{ backgroundColor: "#6a8a2c" }}>
                        <input

                            // value={this.state.searchText}
                            value={this.state.searchText} type="text" className="textBox" 
                            
                            
                            placeholder="Search STB/ Customer name/ Address/ Live Customer/ Disconnected/ MappedCustomer/ UnmappedCustomer/ Unpaid Live" 
                            style={{ lineHeight: 2.3, background: "#6a8a2c", border: 0, width: "100%", borderRadius: 35 }}
                            
                            //    onPaste={this.renderTheSearchResult()}
                            //    onKeyPress={this.renderTheSearchResult()}
                            onChange={(e) => { this.setState({ searchText: e.target.value }) }}
                            onKeyPress={(e) => {

                                if (e.keyCode == 13 || e.which == 13) {
                                    this.getdata1();
                                }
                            }}
                            
                        />
                        <FaSearch className="search-icons"></FaSearch>
                    </SearchDiv>



                    <Row className="mt-0" style={{ height: "63vh", overflow: "auto" }}>
                        {/* {this.renderTheData()} */}
                        {this.renderTheData1()}
                    </Row>
                    <Loader
                        visible={this.state.isShowLoader}
                        style={{ textAlign: "center" }}
                        type="ThreeDots"
                        color="#00BFFF"
                        height="20"
                        width="100"
                    />
                    {/* <Card  className="cards-width">

<Card.Body>
    <CustomerStatusDiv>
    <label>Customer Status</label> <CustomerStatus>Live</CustomerStatus></CustomerStatusDiv>
  <Card.Title><MdPermIdentity/><label style={{color:"#78da24"}}> Name</label><Amt>     {'\u20B9'}957.23 </Amt></Card.Title>
  <Card.Title><MdHome/> Name</Card.Title>
  <Card.Title><MdFiberPin/> Name</Card.Title>
  <Button variant="success" size="lg" block style={{    background: "#85da3c",border: 0,
    fontWeight: 600}}>Pay Now</Button>
    <MoreDetails>
        More Details...
    </MoreDetails>
</Card.Body>
</Card> */}




                </MainContainer>
                {/* <Digital
          position="relative"
          top ={101}
         
          animation={this.state.animating}
          >

          </Digital> */}
                {this.logOutFunc()}

                <Drawer

                    open={this.state.isShowAddressDialog}
                    onRequestClose={this.toggle}
                    direction='bottom'
                >

                    <Card >


                        <Card.Body className="pl-3 pr-3">
                            <div style={{ fontSize: 18, fontWeight: "bold", color: "red" }}>Address:</div>

                            <div style={{ marginLeft: 50, maxHeight: "30vh", marginRight: 30, color: GeneralValues.mustard, overflow: "auto", marginTop: 20 }}>
                                <label>{this.state.selectedAddress}</label>
                            </div>


                            <Button
                                style={{ backgroundColor: "green", borderColor: "green", marginRight: 15 }}
                                className="mt-3 pull-right"
                                onClick={() => {

                                    this.setState({ isShowAddressDialog: false });

                                }}
                            >OK</Button>



                        </Card.Body>
                    </Card>


                </Drawer>

            </Rootcontainer>


        );
    }
}