import React from "react";
// import {Button,ButtonGroup,Container,Card} from 'react-bootstrap';
import {
  Card,
  ButtonGroup,
  Button,
  Col,
  Row,
  Container
} from "react-bootstrap";
import {
  Rootcontainer,
  SearchDiv,
  MainContainer,
  RootDiv,
  RootTitle,
  RootBal,
  RootRight,
  Amt,
  MoreDetails,
  RootLeft,
  RootValue,
  CustomerStatus,
  BtnGroupRight,
  STBGroup,
  STBDetails,
  CustomerStatusDiv,
  ActivPackage,
  ChannelBox,
  ChannelName,
  ChannelPrice,

  AddPackageHeading
} from "./styled";
import API from "../../Api";

import GeneralValues from '../../GobelElements/Values';
import { Redirect } from "react-router-dom";
import logo from "../../assets/logo-3.png";
import LoadingOverlay from "react-loading-overlay";
import loadinggif from "../../assets/loding-gif.gif";
import "../../screens/Transactions/index.css";
import Drawer from "react-drag-drawer";
import {
  MdPowerSettingsNew,
  MdCreditCard,
  MdAddCircle,
  MdRemoveCircle,
  MdNotifications,
  MdInsertDriveFile,
  MdPersonPin,
  MdTrendingUp
} from "react-icons/md";
import { IoIosSettings } from "react-icons/io";

import Loader from 'react-loader-spinner';
import { FaCcMastercard, FaUserPlus, FaCloudDownloadAlt, FaPlus, FaCircle } from "react-icons/fa";
import Undraw from "react-undraw";
import DialogBoxes from './DialogBoxes_index';
export default class index extends React.Component {
  constructor(props) {
    super(props);
    console.log("data from search", this.props.specifyUserDetails);
    const data = this.props.specifyUserDetails;
    console.log("data in customer details", data);
    console.log("data console", this.props.onClickCredit);
    this.state = {
      userMetaData: data,
      userSTBDetails: {},
      currentChecked: [],
      currentCheckedRemove: [],
      currentPack: [],
      isShowAddPackageDialogBox: false,
      isShowRemovePackageDialogBox: false,
      addPackageSearchResultList: [],
      allTransactionsResult: [],
      spinnerVisible: false,
      lcoID: localStorage.getItem("userID"),
      lcoPassword: localStorage.getItem("userPassword"),
      domainName: localStorage.getItem("domain"),
      isShowLogOutDialog: false,
      isShowRechargeWarningDialog: false,
      paygateway: "",
      isShowMantainanceDialog: false,
      maintIMG: "",
      maintText: "",
      isShowDownload: false,
      inStockListSearchText: "",
      inStockDataList: []
    };

    this.getLCO_Details();
  }

  hindRechargeWarningDialog() {
    this.setState({ isShowRechargeWarningDialog: false });
  }
  getLCO_Details() {
    this.setState({ isSpinnerVisible: true });
    const { lcoID, lcoPassword, domainName, lcoBalance } = this.state;
    API.getLOC_Details(lcoID, lcoPassword, "lcodetail", domainName)
      .then(response => response.json())
      .then(responsejson => {
        console.log("repose", responsejson);
        debugger;
        this.setState({
          lcoName: responsejson.lconame,
          lcoBalance: responsejson.balance,
          lcocode: responsejson.lcocode,
          isSpinnerVisible: false,
          paygateway: responsejson.paygateway,
          isShowMantainanceDialog: responsejson.maint,
          maintText: responsejson.mainttext,
          maintIMG: responsejson.maintimage,
          isShowDownload: responsejson.download_reports
        });
        console.log("lcoName", lcoBalance);
      });
  }
  moveToCredit() {
    this.props.onClickCredit();
  }
  moveToDebit() {
    console.log("debit log", this.props.onClickDebit);
    this.props.onClickDebit();
  }
  moveToNotifications() {
    this.props.onClickNotifications();
  }

  moveToActivityLog() {
    const { lcoID, lcoPassword, domainName, lcoBalance } = this.state;
    API.getLcoActivity(lcoID, lcoPassword, "stbactivity", 1, domainName)
      .then(response => response.json())
      .then(responseJson => {
        console.log("response in Activity log", responseJson);
      });
  }
  logOutFunc() {
    if (this.state.isloggedOut) {
      return <Redirect to="/login" />;
    } else {
      return null;
    }
  }
  logOut() {
    localStorage.removeItem("userID");
    const username = localStorage.getItem("userID");
    if (!username) {
      this.setState({ isloggedOut: true });
    }
  }
  stockListSearch(text) {

    var { lcoID, lcoPassword, domainName, inStockListSearchText } = this.state;
    if (text.length > 2) {
      this.setState({ inStockDataList: [], isShowInStockList: true, isShowStockLoader: true, noData: "" });

      API.stockListSearch(lcoID, lcoPassword, "liststock", text, 1, domainName)
        .then((response) => response.json())
        .then((responseJson) => {
          console.log("response", responseJson);
          if (responseJson.count) {


            this.setState({ inStockDataList: responseJson.content, isShowInStockList: true, inStockCurrentPage: 1, totalCountInStock: responseJson.count, isShowStockLoader: false });
            console.log("In stock list", this.state.inStockDataList);
          } else {
            this.setState({ inStockDataList: [], isShowStockLoader: false, noData: "No data found!" });
          }
        });
    } else {
      this.setState({ isShowInStockList: false, isShowInStockList: false, isShowStockLoader: false, noData: "" });
    }
  }
  getSTBDetails(item) {
    console.log("item", item);
    if (item.Customer) {
      this.setState({ spinnerVisible: true });
      const { lcoID, lcoPassword, domainName, searchText } = this.state;
      API.STBSearch(lcoID, lcoPassword, "stbsearch", item.SNo, domainName)
        .then((response) => response.json())
        .then((responsejson) => {
          console.log("response", responsejson);
          if (responsejson.message == "no data") {
            this.setState({ spinnerVisible: false });
          } else {
            localStorage.setItem("currentlco", item.Lcocode);
            this.setState({ spinnerVisible: false });
            this.props.moveToCustomerDetails("customerdetails", responsejson, this.state.searchText);
          }
        });
    }
    else {
      this.setState({ isShowNodataDialog: true })
    }

  }


  render() {
    return (
      <Rootcontainer>
        <LoadingOverlay
          className={this.state.spinnerVisible ? "displaySpinner" : ""}
          overlay={true}
          active={this.state.spinnerVisible}
          spinner={
            <img
              src={loadinggif}
              className="spinnerContent"
              color="green"
              style={{ marginLeft: 100 }}
            />
          }
        ></LoadingOverlay>
        <Drawer
          open={this.state.isShowLogOutDialog}
          onRequestClose={this.toggle}
          direction="left"
        >
          <Card>
            <Card.Body className="pl-3 pr-3">
              <div style={{ fontSize: 15, fontWeight: "bold" }}>
                Do you want{" "}
                <span style={{ fontSize: "1.5em", color: "red" }}>Log out</span>{" "}
                ?
              </div>
              <Undraw className="undrawimg" name="monitor" />
              <Button
                style={{ marginLeft: 20 }}
                className="success"
                className="mt-3 pull-right"
                onClick={() => {
                  this.setState({ isShowLogOutDialog: false });
                }}
              >
                Cancel
              </Button>

              <Button
                style={{ backgroundColor: "red" }}
                className="danger"
                className="mt-3 pull-right"
                onClick={() => {
                  // this.props.hindNodataDialog();
                  this.logOut();
                }}
              >
                OK
              </Button>
            </Card.Body>
          </Card>
        </Drawer>

        <div className="header-div" style={{backgroundColor:GeneralValues.blueColor}}>
          <h3 style={{ marginLeft: '80px',marginRight:"-30px",textTransform:"uppercase" }}>Transactions</h3>
          <img
            onClick={() => { this.props.moveToDashBoard() }}
            src={GeneralValues.circlelogo}
            style={{ float: "left", marginLeft: " -140px" }}
            className="logo-img"
            alt="logo"
          />

          <MdPowerSettingsNew
            onClick={() => {
              this.setState({ isShowLogOutDialog: true });
            }}
            value="Logout"
            className="fontSize login"
          />
          <input type="text" text="Search" placeholder="Global Search..."
            onChange={(e) => {
              this.setState({ inStockListSearchText: e.target.value })
              this.stockListSearch(e.target.value);
            }}

            value={this.state.inStockListSearchText}
            className="input-STB"
            style={{ backgroundColor: "transparent", borderColor: "transparent", borderBottom: "2px solid white", color: "white", textAlign: "center", fontSize: 15, borderRight: "2px solid white", fontWeight: "bold", alignSelf: "end", marginLeft: "50%" }} />
          <div style={{
            position: "absolute",
            display: this.state.isShowInStockList ? "block" : "none",
            listStyle: "none",
            backgroundColor: "white",
            padding: 0,
            boxShadow: "0px 1px 3px #e9b42a",
            zIndex: 9,
            width: "22vw",
            top: 60,
            overflow: "auto",
            maxHeight: "30vh",
            marginLeft: "65%"

          }}
            onScroll={(e) => this.handleScroll(e)}>
            <label style={{ fontSize: 15, fontWeight: "bold", color: "rgb(26, 172, 195)", marginLeft: 10 }}>List:</label>
            <div style={{ textAlign: "center", color: "gray" }}>{this.state.noData}</div>
            <ul style={{ listStyle: "none", padding: 10 }}>

              {this.state.inStockDataList.map((item, i) => {

                return (


                  <li className="search-list"
                    onClick={() => { this.getSTBDetails(item) }}
                    style={{ fontSize: 15, color: "black", lineHeight: 2, borderBottom: "1px solid rgba(26, 172, 195,0.5)", cursor: "pointer" }}>
                    <div>
                      <lable style={{ display: "block" }}>
                        Name:
                        {item.Customer ? item.Customer : <FaPlus style={{
                          background: " #0a3f65",
                          color: "white", width: "20px", height: "20px", padding: "2px", borderRadius: "10px", marginLeft: "8px"
                        }} />}

                        <FaCircle style={{
                          display: item.status ? "inline-block" : "none",
                          height: "12px", float: "right", marginTop: "8px",
                          color: item.status == "Live" ? "green" : "red",
                        }} />


                      </lable>

                      <lable style={{ display: "block" }}>STB: {item.SNo}

                        <div style={{
                          fontSize: "12px", display: item.paid ? "inline-block" : "none", float: "right", color: "white",
                          padding: "0px 7px", height: "25px",
                          backgroundColor: item.paid === "paid" ? "green" : "red",
                        }}>{item.paid}  </div>
                      </lable>

                      <lable style={{ display: "block", fontSize: "12px" }}>Lco: {item.Lconame}

                        <div style={{ fontSize: "12px", display: "inline-block", float: "right", color: "red" }}>({item.Lcocode})
                        </div>
                      </lable>
                    </div>
                  </li>

                );
              })}
            </ul>

            <Loader
              visible={this.state.isShowStockLoader}
              style={{ textAlign: "center", marginBottom: 10, }}
              type="ThreeDots"
              color="#00BFFF"
              height="10"
              width="20"
            />
          </div>
        </div>

        <RootDiv>
          <RootLeft>
            <RootTitle>Name :</RootTitle>

            <RootValue
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.props.onClickProfile();
              }}
            >
              {this.state.lcoName}
            </RootValue>
            <div className="lcocode">
              (<span style={{ color: "black" }}>Lco code:</span>{" "}
              {this.state.lcocode})
            </div>
          </RootLeft>

          <RootRight>
            <RootTitle>Balance :</RootTitle>

            <RootValue>
              {"\u20B9"} {this.state.lcoBalance}
            </RootValue>
          </RootRight>
        </RootDiv>

        <MainContainer>
          {/* <Container>
            <div className="d-flex flex-column transactions-btn">
              <ButtonGroup
                aria-label="Basic example"
                size="lg"
                className="mt-3"
              >
                <Button
                  variant="light"
                  size="lg"
                  onClick={() => {
                    this.moveToCredit();
                  }}
                >
                  <MdAddCircle />
                  Credit
                </Button>
                <Button
                  variant="light"
                  size="lg"
                  onClick={() => {
                    this.moveToDebit();
                  }}
                >
                  <MdRemoveCircle />
                  Debit
                </Button>
                <Button
                  variant="light"
                  size="lg"
                  onClick={() => {
                    this.moveToNotifications();
                  }}
                >
                  <MdNotifications />
                  Notifications
                </Button>
              </ButtonGroup>
              <div style={{ display: "flex", width: "100%" }}>
                <div style={{ marginTop: 30, float: "left", width: "30%" }}>
                  <Button
                    onClick={() => {
                      this.props.onClickProfile();
                    }}
                    style={{ width: "100%", color: "#802915", height: 126 }}
                    variant="light"
                  >
                    <MdPersonPin />
                    Lco Details
                  </Button>
                </div>
                <div
                  style={{
                    marginTop: 30,
                    float: "left",
                    width: "30%",
                    marginLeft: 30
                  }}
                >
                  <Button
                    onClick={() => {
                      this.props.onClickActivityLog();
                    }}
                    style={{
                      width: "100%",
                      color: "#2E4172",
                      height: 126,
                      float: "left"
                    }}
                    variant="light"
                  >
                    <MdTrendingUp />
                    Activity
                  </Button>
                </div>
              </div>
            </div>
          </Container> */}

          {/* <Container > */}
            <Row className="transcation" style={{width:"98vw"}}>
              <Col
                md={3}
                sm={12}
                style={{ margin: "10px 0px 50px", cursor: "pointer" }}
                onClick={() => {
                  this.moveToCredit();
                }}
              >

                <Card
                  className="transaction-card"
                  style={{
                    height: 80,
                    color: "#fff",
                    background: "linear-gradient(to right, #825512, #F39F23)"
                  }}
                >
                  <center>
                    <Row style={{ height: 80 }}>
                      <MdCreditCard
                        style={{ marginTop: 40 }}
                      />
                        <Card.Text style={{ color: "#fff", fontSize: 15, fontWeight: "bold",width:"12rem",textTransform:"uppercase"}}>
                        Credit
                      </Card.Text>
                      
                    </Row>
                  </center>

                </Card>
              </Col>
              <Col
                md={3}
                sm={12}
                style={{ margin: "10px 0px 50px", cursor: "pointer" }}
                onClick={() => {
                  this.moveToDebit();
                }}
              >

                <Card
                  className="transaction-card"
                  style={{
                    height: 80,
                    color: "#fff",
                    background: "linear-gradient(to right, #582560, #5967BA)"
                  }}
                >
                  <center>
                    <Row style={{ height: 80 }}>
                      <FaCcMastercard
                        style={{ marginTop: 40 }}
                      />
                       <Card.Text style={{ color: "#fff", fontSize: 15, fontWeight: "bold",width:"12rem",textTransform:"uppercase"}}>
                       Debit
                      </Card.Text>
                    
                    </Row>
                  </center>

                </Card>
                {/* <Card
                  className="transaction-card"
                  style={{
                    boxShadow: "0.5px 0.5px 2px 2px #958f88",
                    color: "#fff"
                  }}
                >
                  <div className="circle">
                    <FaCcMastercard
                      style={{
                        background: GeneralValues.blueColor
                      }}
                    />
                  </div>

                  <Card.Text style={{ marginTop: "-15px", color: GeneralValues.mustard, fontSize: 15, fontWeight: "bold" }}>
                    Debit
                  </Card.Text>
                </Card> */}
              </Col>

              <Col
                md={3}
                sm={12}
                style={{ margin: "10px 0px 50px", cursor: "pointer" }}
                onClick={() => {
                  this.props.onClickProfile();
                }}
              >

                <Card
                  className="transaction-card"
                  style={{
                    height: 80,
                    color: "#fff",
                    background: "linear-gradient(to right, #333B6A, #5765B6)"
                  }}
                >
                  <center>
                    <Row style={{ height: 80 }}>
                      <MdPersonPin
                        style={{ marginTop: 40 }}
                      />
                     
                      <Card.Text style={{ color: "#fff", fontSize: 15, fontWeight: "bold",width:"12rem",textTransform:"uppercase"}}>
                      Lco Details
                      </Card.Text>
                    </Row>
                  </center>
                </Card>
                {/* <Card
                  className="transaction-card"
                  style={{
                    boxShadow: "0.5px 0.5px 2px 2px #958f88",
                    color: "#fff"
                  }}
                >
                  <div className="circle">
                    <MdPersonPin
                      style={{
                        background: GeneralValues.blueColor
                      }}
                    />
                  </div>

                  <Card.Text style={{ marginTop: "-15px", color: GeneralValues.mustard, fontSize: 15, fontWeight: "bold" }}>
                    Lco Details
                  </Card.Text>
                </Card> */}
              </Col>

              <Col
                md={3}
                sm={12}
                style={{ margin: "10px 0px 50px", cursor: "pointer" }}
                onClick={() => {
                  this.moveToNotifications();
                }}
              >
                <Card
                  className="transaction-card"
                  style={{
                    height: 80,
                    color: "#fff",
                    background: "linear-gradient(to right, #2A645E, #30C1D2)"
                  }}
                >
                  <center>
                    <Row style={{ height: 80 }}>
                      <MdNotifications
                        style={{ marginTop: 40 }}
                      />
                     
                      <Card.Text style={{ color: "#fff", fontSize: 15, fontWeight: "bold",width:"12rem",textTransform:"uppercase"}}>
                      Notifications
                      </Card.Text>
                    </Row>
                  </center>
                </Card>

                {/* <Card
                  className="transaction-card"
                  style={{
                    boxShadow: "0.5px 0.5px 2px 2px #958f88",
                    color: "#fff"
                  }}
                >
                  <div className="circle">
                    <MdNotifications
                      style={{
                        background: GeneralValues.blueColor
                      }}
                    />
                  </div>

                  <Card.Text style={{ marginTop: "-15px", color: GeneralValues.mustard, fontSize: 15, fontWeight: "bold" }}>
                    Notifications
                  </Card.Text>
                </Card> */}
              </Col>

              <Col
                md={3}
                sm={12}
                style={{ margin: "10px 0px 50px", cursor: "pointer" }}
                onClick={() => {
                  this.props.onClickActivityLog();
                }}
              >

                <Card
                  className="transaction-card"
                  style={{
                    height: 80,
                    color: "#fff",
                    background: "linear-gradient(to right, #2B6885, #4AB2E2)"
                  }}
                >
                  <center>
                    <Row style={{ height: 80 }}>
                      <MdTrendingUp
                        style={{ marginTop: 40 }}
                      />
                     
                      <Card.Text style={{ color: "#fff", fontSize: 15, fontWeight: "bold",width:"12rem",textTransform:"uppercase"}}>
                      Activity
                      </Card.Text>
                    </Row>
                  </center>
                </Card>

                {/* <Card
                  className="transaction-card"
                  style={{
                    boxShadow: "0.5px 0.5px 2px 2px #958f88",
                    color: "#fff"
                  }}
                >
                  <div className="circle">
                    <MdTrendingUp
                      style={{
                        background: GeneralValues.blueColor
                      }}
                    />
                  </div>

                  <Card.Text
                    style={{

                      //  marginTop: "-15px"
                      marginTop: "-15px", color: GeneralValues.mustard, fontSize: 15, fontWeight: "bold"
                    }}
                  >
                    Activity
                  </Card.Text>
                </Card> */}
              </Col>

              <Col
                md={3}
                sm={12}
                style={{ margin: "10px 0px 50px", cursor: "pointer" }}
                onClick={() => {
                  this.props.moveToUnmappedSTB();
                }}
              >

                <Card
                  className="transaction-card"
                  style={{
                    height: 80,
                    color: "#fff",
                    background: "linear-gradient(to right, #1B6670, #31BBCE)"
                  }}
                >
                  <center>
                    <Row style={{ height: 80 }}>
                      <FaUserPlus
                        style={{ marginTop: 40 }}
                      />
                      <Card.Text style={{ color: "#fff", fontSize: 15, fontWeight: "bold",width:"12rem",textTransform:"uppercase"}}>
                      Add Customer
                      </Card.Text>
                     
                    </Row>
                  </center>
                </Card>

                {/* <Card
                  className="transaction-card"
                  style={{
                    boxShadow: "0.5px 0.5px 2px 2px #958f88",
                    color: "#fff"
                  }}
                >
                  <div className="circle">
                
                    <FaUserPlus
                      style={{
                        padding: 12,
                        background: GeneralValues.blueColor
                      }}
                    />
                  </div>

                  <Card.Text
                    style={{
                      marginTop: "-15px", color: GeneralValues.mustard, fontSize: 15, fontWeight: "bold"
                    }}
                  >
                    Add Customer
                  </Card.Text>
                </Card> */}
              </Col>
              {/* this.state.isShowDownload */}
              {true
                ?
                <Col
                  md={3}
                  sm={12}
                  style={{ margin: "10px 0px 50px", cursor: "pointer" }}
                  onClick={() => {
                    this.props.moveToReport();
                  }}
                >
                  <Card
                    className="transaction-card"
                    style={{
                      height: 80,
                      color: "#fff",
                      background: "linear-gradient(to right, #9AE7D3, #1891B2)"
                    }}
                  >
                    <center>
                      <Row style={{ height: 80 }}>
                        <FaCloudDownloadAlt
                          style={{ marginTop: 40 }}
                        />
                         <Card.Text style={{ color: "#fff", fontSize: 15, fontWeight: "bold",width:"12rem",textTransform:"uppercase"}}>
                         Download Report
                      </Card.Text>
                       
                      </Row>
                    </center>
                  </Card>

                  {/* <Card
                    className="transaction-card"
                    style={{
                      boxShadow: "0.5px 0.5px 2px 2px #958f88",
                      color: "#fff"
                    }}
                  >
                    <div className="circle">
                      <FaCloudDownloadAlt
                        style={{
                          padding: 12,
                          background: GeneralValues.blueColor
                        }}
                      />
                    </div>

                    <Card.Text style={{ marginTop: "-22px", color: GeneralValues.mustard, fontSize: 15, fontWeight: "bold" }}>
                      Download Report
                    </Card.Text>
                  </Card> */}
                </Col>
                : null
              }


              <Col
                md={3}
                sm={12}
                style={{ margin: "10px 0px 50px", cursor: "pointer" }}
                onClick={() => {
                  this.props.moveToBulkCustomerCreation();
                }}
              >

                <Card
                  className="transaction-card"
                  style={{
                    height: 80,
                    color: "#fff",
                    background: "linear-gradient(to right, #21547D, #3F9CE8)"
                    // padding:0,
                    // margin:0,
                    // width: 200
                  }}
                >
                
                    <Row style={{ height: 80 }}>
                      <IoIosSettings
                        style={{ marginTop: 40 }}
                      />
                      <Card.Text style={{ color: "#fff", fontSize: 15, fontWeight: "bold",width:"12rem",textTransform:"uppercase"}}>
                        Bulk customer creation
                      </Card.Text>
                    </Row>
               
                </Card>

                {/* <Card
                  className="transaction-card"
                  style={{
                    boxShadow: "0.5px 0.5px 2px 2px #958f88",
                    color: "#fff"
                  }}
                >
                  <div className="circle">
                   
                    <IoIosSettings
                      style={{
                        padding: 12,
                        background: GeneralValues.blueColor
                      }}
                    />
                  </div>

                  <Card.Text style={{ marginTop: "-22px", color: GeneralValues.mustard, fontSize: 15, fontWeight: "bold" }}>
                    Bulk customer creation
                  </Card.Text>
                </Card> */}
              </Col>

              <Col
                md={3}
                sm={12}
                style={{ margin: "10px 0px 50px", cursor: "pointer" }}
                onClick={() => {
                  this.props.moveToMonthlyLedger();
                }}
              >

                <Card
                  className="transaction-card"
                  style={{
                    height: 80,
                    color: "#fff",
                    background: "linear-gradient(to right, #85EAFA, #E16FE7)"
                  }}
                >
                  <center>
                    <Row style={{ height: 80 }}>
                      <MdCreditCard
                        style={{ marginTop: 40 }}
                      />
                   
                      <Card.Text style={{ color: "#fff", fontSize: 15, fontWeight: "bold",width:"12rem",textTransform:"uppercase"}}>
                      Monthly-ledger
                      </Card.Text>
                    </Row>
                  </center>
                </Card>

                {/* <Card
                  className="transaction-card"
                  style={{
                    boxShadow: "0.5px 0.5px 2px 2px #958f88",
                    color: "#fff"
                  }}
                >
                  <div className="circle">
                    <MdCreditCard
                      style={{
                        background: GeneralValues.blueColor
                      }}
                    />
                  </div>

                  <Card.Text style={{ marginTop: "-15px", color: GeneralValues.mustard, fontSize: 15, fontWeight: "bold" }}>
                    Monthly ledger
                  </Card.Text>
                </Card> */}
              </Col>
              <Col
                md={3}
                sm={12}
                style={{ margin: "10px 0px 50px", cursor: "pointer" }}
                onClick={() => {
                  this.props.moveToAllSTB();
                }}
              >

                <Card
                  className="transaction-card"
                  style={{
                    height: 80,
                    color: "#fff",
                    background: "linear-gradient(to right, #FEAE9B, #8DC0F3)"
                  }}
                >
                  <center>
                    <Row style={{ height: 80 }}>
                      <MdCreditCard
                        style={{ marginTop: 40 }}
                      />
                    
                      <Card.Text style={{ color: "#fff", fontSize: 15, fontWeight: "bold",width:"12rem",textTransform:"uppercase"}}>
                      All STB
                      </Card.Text>
                    </Row>
                  </center>
                </Card>

                {/* <Card
                  className="transaction-card"
                  style={{
                    boxShadow: "0.5px 0.5px 2px 2px #958f88",
                    color: "#fff"
                  }}
                >
                  <div className="circle">
                    <MdCreditCard
                      style={{
                        background: GeneralValues.blueColor
                      }}
                    />
                  </div>

                  <Card.Text style={{ marginTop: "-15px", color: GeneralValues.mustard, fontSize: 15, fontWeight: "bold" }}>
                    All STB
                  </Card.Text>
                </Card> */}
              </Col>


              <Col
                md={3}
                sm={12}
                style={{
                  margin: "10px 0px 50px",
                  cursor: "pointer",
                  display: this.state.paygateway !== "" ? "block" : "none"
                }}
                onClick={() => {
                  this.setState({ isShowRechargeWarningDialog: true });
                }}
              >

                <Card
                  className="transaction-card"
                  style={{
                    height: 80,
                    color: "#fff",
                    background: "linear-gradient(to right, #825512, #F39F23)"
                  }}
                >
                  <center>
                    <Row style={{ height: 80 }}>
                      <IoIosSettings
                        style={{ marginTop: 40 }}
                      />
                      <Card.Text style={{ color: "#fff", fontSize: 15, fontWeight: "bold",marginTop:-20,width:"12rem",textTransform:"uppercase"}}>
                      Balance Recharge
                      </Card.Text>
                      
                    </Row>
                  </center>
                </Card>

                {/* <Card
                  className="transaction-card"
                  style={{
                    boxShadow: "0.5px 0.5px 2px 2px #958f88",
                    color: "#fff"
                  }}
                >
                  <div className="circle">
                 
                    <IoIosSettings
                      style={{
                        padding: 12,
                        background: GeneralValues.blueColor
                      }}
                    />
                  </div>

                  <Card.Text style={{ marginTop: "-22px", color: GeneralValues.mustard, fontSize: 15, fontWeight: "bold" }}>
                    Balance Recharge
                  </Card.Text>
                </Card> */}
              </Col>
            </Row>
          {/* </Container> */}


          <DialogBoxes
            lcocode={this.state.lcocode}
            lcoID={this.state.lcoID}
            isShowRechargeWarningDialog={this.state.isShowRechargeWarningDialog}
            hindRechargeWarningDialog={this.hindRechargeWarningDialog.bind(this)}
            paygateway={this.state.paygateway}
            isShowMantainanceDialog={this.state.isShowMantainanceDialog}
            maintIMG={this.state.maintIMG}
            maintText={this.state.maintText}
            getLOC_Details={this.getLCO_Details.bind(this)}

          />
          <div
            style={{ textAlign: "center", fontSize: 15, fontWeight: "bold" }}
          >

            {/* <img scr={loadinggif} style={{width:500,height:400}} alt="data image"></img> */}
            Version 6.31
          </div>
        </MainContainer>
        {this.logOutFunc()}
      </Rootcontainer>
    );
  }
}
