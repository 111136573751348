import styled from 'styled-components';
import GeneralValue from '../../GobelElements/Values';
export const Rootcontainer = styled.div `
width:100%;

`


export const RootDiv=styled.div `
width: 100%;
    display: table;
padding: 3px 11px;
border-bottom: 1px solid whitesmoke;    background-color: #fbfbfb3d;
line-height:2;
`

export const RootTitle=styled.label `
float:left;    font-size: 23px;
font-weight: 600;margin-bottom:0px;
`

export const RootBal=styled.label `
float:right;    font-size: 23px;
font-weight: 600;margin-bottom:0px;
`

export const RootLeft=styled.div `
float: left;
`

export const RootRight = styled.div `
float:right;margin-bottom:0px;
`

export const RootValue = styled.label `
    color:#1bcd56;
    font-size: 23px;
    font-weight: 600;margin-bottom:0px;
    `

