import React from 'react';
import Drawer from 'react-drag-drawer';
import './index.css';
import Undraw from 'react-undraw';
import { Button, ButtonGroup, Container, ButtonToolbar, Jumbotron, Card } from 'react-bootstrap';
export default class DialogBoxs extends React.Component {
    isShowOkButtonInCSVOperation(completed, total) {
        var tot = parseInt(total);
        if (completed == tot) {
            return (
                <Button
                    style={{ float: "none", marginLeft: 124 }}
                    className="success" className="mt-3 pull-right"
                    onClick={() => {

                        this.props.hindBulkCSVOperationRunning();

                    }}
                >OK</Button>
            );
        } else {
            return null;
        }
    }
    isShowFailedCSVdata(data) {

        if (data) {
            return (
                <div style={{ marginLeft: 20, maxHeight: 232, width: 269, overflow: "auto" }}>{data.map((item, i) => {
                    return (
                        <div style={{ color: "#d61818", fontSize: 16 }}>- {item.SNo}</div>
                    );
                })}</div>
            );
        } else {
            return null;
        }
    }

    isShowOkButtonInCSVFileUpload() {
        console.log("data in csv", this.props.noOfCSVFileUploaded, this.props.numberOfRowsInCsv)
        if (this.props.noOfCSVFileUploaded == this.props.numberOfRowsInCsv) {
            return (
                <Button
                    style={{
                        backgroundColor: this.props.un.length ? "red":"green",
                        borderColor: this.props.un.length ? "red":"green",
                        color:"white"
                    }}

                    className="mt-3 pull-right"
                    onClick={() => {
                        this.props.hindCSVFileUploadDialog();

                    }}
                >{this.props.un.length ? "Show Unmapped STB":"OK"}</Button>
            );
        } else {
            return null;
        }
    }
    render() {
        return (
            <div>


                <Drawer

                    open={this.props.isBulkCSVOperationRunning}
                    onRequestClose={this.toggle}
                    direction='left'
                >

                    <Card >


                        <Card.Body className="pl-3 ">
                            <div style={{ fontSize: 18, fontWeight: 'bold' }}>
                                <label style={{ color: "red", fontSize: 25, fontWeight: "bold", marginLeft: 20, marginRight: 20 }}>
                                    {this.props.CompletedCustomerNum}
                                    <label style={{ color: "black", fontSize: 18, }}> {"\t"} of {"\t"} </label>
                                    {this.props.noOfCustomerCSVSelect}
                                    <label style={{ color: "black", fontSize: 18, }}> Completed Successfully</label>
                                </label></div>
                            {this.isShowOkButtonInCSVOperation(this.props.CompletedCustomerNum, this.props.noOfCustomerCSVSelect)}



                        </Card.Body>
                    </Card>

                </Drawer>

                <Drawer

                    open={this.props.isShowFailedCSVDialog}
                    onRequestClose={this.toggle}
                    direction='left'
                >

                    <Card >


                        <Card.Body className="pl-3 pr-3">
                            <div style={{ fontSize: 18, fontWeight: 'bold', color: "rgb(26, 172, 195)" }}>Failed STB List:</div>

                            {this.isShowFailedCSVdata(this.props.failedCSVData)}
                            <Button

                                className="success" className="mt-3 pull-right"
                                onClick={() => {

                                    this.props.hindfailedCSVDialog();

                                }}
                            >OK</Button>



                        </Card.Body>
                    </Card>

                </Drawer>

                <Drawer

                    open={this.props.isShowWarningPayCheck}
                    onRequestClose={this.toggle}
                    direction='left'
                >

                    <Card >


                        <Card.Body className="pl-3 pr-3">
                            <div style={{ fontSize: 18, fontWeight: 'bold' }}>Are you sure to pay all <span style={{ color: "red", fontSize: 18 }}>Live unpaid customer</span> ?</div>


                            <Button

                                className="success" className="mt-3 pull-right"
                                onClick={() => {

                                    this.props.payAllCheckFun();

                                }}
                            >OK</Button>

                            <Button
                                style={{ marginRight: 15, backgroundColor: 'red' }}

                                className="danger" className="mt-3 pull-right"
                                onClick={() => {

                                    this.props.hindPayAllWarningDialog();

                                }}
                            >Cancel</Button>



                        </Card.Body>
                    </Card>

                </Drawer>

                <Drawer

                    open={this.props.isShowSuccessPayCheck}
                    onRequestClose={this.toggle}
                    direction='left'
                >

                    <Card >


                        <Card.Body className="pl-3 pr-3">
                            <div style={{ fontSize: 15, fontWeight: 'bold' }}>
                                <label>Lco Balance :</label>
                                <label style={{ fontWeight: "bold", color: "red", fontSize: 18 }}>{this.props.lcoBalance}</label>
                            </div>
                            <div style={{ fontSize: 15, fontWeight: 'bold' }}>
                                <label>Require Amount :</label>
                                <label style={{ fontWeight: "bold", color: "red", fontSize: 18 }}>{this.props.payAllRequireAmount}</label>
                            </div>
                            <div style={{ fontSize: 15, fontWeight: 'bold' }}>
                                <label>Remaining Balance :</label>
                                <label style={{ fontWeight: "bold", color: "red", fontSize: 18 }}>{(this.props.lcoBalance - this.props.payAllRequireAmount).toFixed(2)}</label>
                            </div>


                            <Button

                                className="success" className="mt-3 pull-right"
                                onClick={() => {

                                    this.props.payAllFun();

                                }}
                            >OK</Button>
                            <Button
                                style={{ marginRight: 15, backgroundColor: 'red' }}

                                className="danger" className="mt-3 pull-right"
                                onClick={() => {

                                    this.props.hindPayAllCheckSuccessDialog();

                                }}
                            >Cancel</Button>



                        </Card.Body>
                    </Card>

                </Drawer>

                <Drawer

                    open={this.props.isShowPayAllSuccessDialog}
                    onRequestClose={this.toggle}
                    direction='left'
                >

                    <Card >


                        <Card.Body className="pl-3 pr-3">
                            <div style={{ fontSize: 18, fontWeight: 'bold' }}>Request sent </div>


                            <Button

                                className="success" className="mt-3 pull-right"
                                onClick={() => {

                                    this.props.hindPayAllSuccessDialog();

                                }}
                            >OK</Button>



                        </Card.Body>
                    </Card>

                </Drawer>

                <Drawer

                    open={this.props.isLcoBalanceDialog}
                    onRequestClose={this.toggle}
                    direction='left'
                >

                    <Card >


                        <Card.Body className="pl-3 pr-3">
                            <div style={{ fontSize: 18, fontWeight: 'bold' }}> Your  <label style={{ color: "red" }}> Balance Low </label> to Process the operation </div>


                            <Button

                                className="success" className="mt-3 pull-right"
                                onClick={() => {

                                    this.props.hindLowBalanceDialog();

                                }}
                            >OK</Button>



                        </Card.Body>
                    </Card>

                </Drawer>

                <Drawer

                    open={this.props.isShowWarningForInversePay}
                    onRequestClose={this.toggle}
                    direction='left'
                >

                    <Card >


                        <Card.Body className="pl-3 pr-3">
                            <div style={{ fontSize: 18, fontWeight: 'bold' }}> Do you want to process  <label style={{ color: "red" }}> Inverse pay </label> operation ? </div>




                            <Button

                                className="success" className="mt-3 pull-right"
                                onClick={() => {
                                    this.props.hindWarningInversePayDialog();
                                    this.props.stbInversePaysto();

                                }}
                            >OK</Button>
                            <Button
                                style={{ marginRight: 15, backgroundColor: 'red' }}

                                className="danger" className="mt-3 pull-right"
                                onClick={() => {

                                    this.props.hindWarningInversePayDialog();

                                }}
                            >Cancel</Button>



                        </Card.Body>
                    </Card>

                </Drawer>

                <Drawer

                    open={this.props.isShowSuccessInversePayReplay}
                    onRequestClose={this.toggle}
                    direction='left'
                >

                    <Card >


                        <Card.Body className="pl-3 pr-3">
                            <div style={{ fontSize: 18, fontWeight: 'bold' }}> Total Amount :<label style={{ color: "red" }}> {this.props.inversePayTopayAmount}</label>  </div>




                            <Button

                                className="success" className="mt-3 pull-right"
                                onClick={() => {

                                    this.props.hindShowSuccessInversePayReplay();
                                    this.props.stbInversePayConfirm();
                                }}
                            >OK</Button>
                            <Button
                                style={{ marginRight: 15, backgroundColor: 'red' }}

                                className="danger" className="mt-3 pull-right"
                                onClick={() => {

                                    this.props.hindShowSuccessInversePayReplay();

                                }}
                            >Cancel</Button>



                        </Card.Body>
                    </Card>

                </Drawer>

                <Drawer

                    open={this.props.isShowLowBalanceInInversePay}
                    onRequestClose={this.toggle}
                    direction='left'
                >

                    <Card >


                        <Card.Body className="pl-3 pr-3">
                            <div style={{ fontSize: 18, fontWeight: 'bold' }}>Total Amount : <label style={{ color: "red" }}> {this.props.inversePayTopayAmount}</label>  </div>
                            <div style={{ fontSize: 18, fontWeight: 'bold' }}>Lco Balance : <label style={{ color: "red" }}> {this.props.lcoBalance}</label>  </div>
                            <div style={{ fontSize: 18, fontWeight: 'bold' }}> <label style={{ color: "red" }}> Low Balance !</label>  </div>



                            <Button

                                className="success" className="mt-3 pull-right"
                                onClick={() => {
                                    this.props.hindLowBalanceInInversePay();

                                }}
                            >OK</Button>




                        </Card.Body>
                    </Card>

                </Drawer>

                <Drawer

                    open={this.props.isShowCSVFileUploadDialog}
                    onRequestClose={this.toggle}
                    direction='left'
                >

                    <Card >


                        <Card.Body className="pl-3 pr-3">
                            <div style={{ fontSize: 18, fontWeight: 'bold' }}> <label style={{ color: "red" }}> {this.props.noOfCSVFileUploaded}</label> of <label style={{ color: "red" }}> {this.props.numberOfRowsInCsv}</label> Upload sucessfully </div>




                            {this.isShowOkButtonInCSVFileUpload()}




                        </Card.Body>
                    </Card>

                </Drawer>

                <Drawer

                    open={this.props.isShowPayAllLowBalanceDialog}
                    onRequestClose={this.toggle}
                    direction='left'
                >

                    <Card >


                        <Card.Body className="pl-3 pr-3">
                            <div style={{ fontSize: 18, fontWeight: 'bold' }}>Total Amount : <label style={{ color: "red" }}> {this.props.payAllRequireAmount}</label>  </div>
                            <div style={{ fontSize: 18, fontWeight: 'bold' }}>Lco Balance : <label style={{ color: "red" }}> {this.props.lcoBalance}</label>  </div>
                            <div style={{ fontSize: 18, fontWeight: 'bold' }}> <label style={{ color: "red" }}> Low Balance !</label>  </div>

                            <Button

                                className="success" className="mt-3 pull-right"
                                onClick={() => {
                                    this.props.hindPayAllLowBalanceDialog();

                                }}
                            >OK</Button>




                        </Card.Body>
                    </Card>

                </Drawer>

                <Drawer

                    open={this.props.isShowRefreshWarningDialog}
                    onRequestClose={this.toggle}
                    direction='left'
                >

                    <Card >


                        <Card.Body className="pl-3 pr-3">
                            <div style={{ fontSize: 18, }}>Do you want <span style={{ fontWeight: "bold", color: "red" }}>Refresh </span>?</div>
                            <div style={{ textAlign: "end" }}>
                                <Button
                                    style={{ marginRight: 15, backgroundColor: "red" }}
                                    className="danger" className="mt-3 pull-right"
                                    onClick={() => {
                                        this.props.hindRefreshWarningDialog();

                                    }}
                                >Cancel</Button>
                                <Button

                                    style={{ marginRight: 15, backgroundColor: "green" }}
                                    className="success" className="mt-3 pull-right"
                                    onClick={() => {
                                        this.props.refreshDataAll();

                                    }}
                                >OK</Button>
                            </div>




                        </Card.Body>
                    </Card>

                </Drawer>
                <Drawer

                    open={this.props.isShowWarningForPayNow}
                    onRequestClose={this.toggle}
                    direction='left'
                >

                    <Card >


                        <Card.Body className="pl-3 pr-3">
                            <div style={{ fontSize: 18, fontWeight: "bold" }}>Pay Now</div>
                            <div style={{ fontSize: 18, }}>Do you want <span style={{ fontWeight: "bold", color: "red" }}>Pay Now </span>?</div>
                            <div style={{ textAlign: "end" }}>
                                <Undraw
                                    style={{ marginTop: 10, display: 'block', width: 461, marginBottom: 10, height: 263 }}
                                    name="credit-card-payments" />
                                <Button
                                    style={{ marginRight: 15, backgroundColor: "red" }}
                                    className="danger" className="mt-3 pull-right"
                                    onClick={() => {
                                        this.props.hindWarningForPayNow();

                                    }}
                                >Cancel</Button>
                                <Button

                                    style={{ marginRight: 15, backgroundColor: "green" }}
                                    className="success" className="mt-3 pull-right"
                                    onClick={() => {
                                        this.props.hindWarningForPayNow();
                                        this.props.bulkPayActivate();

                                    }}
                                >OK</Button>
                            </div>




                        </Card.Body>
                    </Card>

                </Drawer>

                <Drawer

                    open={this.props.isShowWarningForActivate}
                    onRequestClose={this.toggle}
                    direction='left'
                >

                    <Card >


                        <Card.Body className="pl-3 pr-3">
                            <div style={{ fontSize: 18, fontWeight: "bold" }}>Activate</div>
                            <div style={{ fontSize: 18, }}>Do you want <span style={{ fontWeight: "bold", color: "red" }}>Activate </span>?</div>
                            <div style={{ textAlign: "end" }}>
                                <Undraw
                                    style={{ marginTop: 10, display: 'block', width: 461, marginBottom: 10, height: 263 }}
                                    name="broadcast" />
                                <Button
                                    style={{ marginRight: 15, backgroundColor: "red" }}
                                    className="danger" className="mt-3 pull-right"
                                    onClick={() => {
                                        this.props.hindWarningForActivate();

                                    }}
                                >Cancel</Button>
                                <Button

                                    style={{ marginRight: 15, backgroundColor: "green" }}
                                    className="success" className="mt-3 pull-right"
                                    onClick={() => {
                                        this.props.hindWarningForActivate();
                                        this.props.bulkPayActivate();

                                    }}
                                >OK</Button>
                            </div>




                        </Card.Body>
                    </Card>

                </Drawer>

                <Drawer

                    open={this.props.isShowWarningForDeactivate}
                    onRequestClose={this.toggle}
                    direction='left'
                >

                    <Card >


                        <Card.Body className="pl-3 pr-3">
                            <div style={{ fontSize: 18, fontWeight: "bold" }}>Deactivate</div>
                            <div style={{ fontSize: 18, }}>Do you want <span style={{ fontWeight: "bold", color: "red" }}>Deactivate </span>?</div>
                            <div style={{ textAlign: "end" }}>
                                <Undraw
                                    style={{ marginTop: 10, display: 'block', width: 461, marginBottom: 10, height: 263 }}
                                    name="warning" />
                                <Button
                                    style={{ marginRight: 15, backgroundColor: "red" }}
                                    className="danger" className="mt-3 pull-right"
                                    onClick={() => {
                                        this.props.hindWarningForDeactivate();

                                    }}
                                >Cancel</Button>
                                <Button

                                    style={{ marginRight: 15, backgroundColor: "green" }}
                                    className="success" className="mt-3 pull-right"
                                    onClick={() => {
                                        this.props.hindWarningForDeactivate();
                                        this.props.bulkDisconnect();

                                    }}
                                >OK</Button>
                            </div>




                        </Card.Body>
                    </Card>

                </Drawer>

                <Drawer

                    open={this.props.isShowUnprocessedData}
                    onRequestClose={this.toggle}
                    direction='left'
                >

                    <Card >


                        <Card.Body className="pl-3 pr-3">
                            <div style={{ fontSize: 18, fontWeight: "bold" }}>Unprocessed STB list:</div>
                           <div style={{marginTop:10}}> No of Unprocessed STb: <span style={{color:"red",fontWeight:"bold"}}>{this.props.un.length}</span></div>
                           <div style={{marginLeft:10,marginRight:15,maxHeight:"30vh",overflow:"auto"}}>
                                {this.props.un.map((item)=>{
                                    return(
                                        <div style={{marginTop:10,border:"1px solid gray",padding:5}}>
                                        <label style={{display:"block"}}>STB No : <span style={{color:"red",fontWeight:"bold"}}>{item.sno}</span></label>
                                        <label style={{display:"block",marginTop:5}}>Error : <span style={{color:"red",fontWeight:"bold"}}>{item.error}</span></label>
                                        </div>
                                    );
                                })}
                           </div>
                            <div style={{ textAlign: "end" }}>
                               
                             
                                <Button

                                    style={{ marginRight: 15, backgroundColor: "green" }}
                                    className="success" className="mt-3 pull-right"
                                    onClick={() => {
                                        this.props.hindUnprocessedData();
                                        

                                    }}
                                >OK</Button>
                            </div>




                        </Card.Body>
                    </Card>

                </Drawer>

            </div>
        );
    }
}