import React from 'react';
import Drawer from 'react-drag-drawer';
import './index.css';
import Undraw from 'react-undraw';
import { Button, ButtonGroup, Container, ButtonToolbar, Jumbotron, Card } from 'react-bootstrap';
export default class DialogBoxs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedLco: {},
            isShowTransferWarning: false
        }
    }
    showOkBtnInProcessing() {
        if (this.props.noOfcompletedData == this.props.noOfDataGoingToProcess) {
            return (
                <Button
                    style={{ backgroundColor: this.props.unProcessedData.length ? "red" : "green", borderColor: "green", marginRight: 15 }}
                    className="mt-3 pull-right"
                    onClick={() => {

                        this.props.hindProcessDialog();

                    }}
                >{this.props.unProcessedData.length ? "Show Unprocess STB" : "OK"}</Button>
            );
        } else {
            return null;
        }
    }

    render() {
        return (
            <div>


                <Drawer

                    open={this.props.isShowLogOutWarning}
                    onRequestClose={this.toggle}
                    direction='left'
                >

                    <Card >


                        <Card.Body className="pl-3 pr-3">
                            <div style={{ fontSize: 18, }}>Do you want <span style={{ color: "red", fontWeight: "bold" }}>Log Out</span> ?</div>
                            <Undraw className="undrawimg" name="monitor" />

                            <Button
                                style={{ backgroundColor: "red", borderColor: "red" }}
                                className="mt-3 pull-right"
                                onClick={() => {

                                    this.props.hindLogOutDialog();

                                }}
                            >cancel</Button>
                            <Button
                                style={{ backgroundColor: "green", borderColor: "green", marginRight: 15 }}
                                className="mt-3 pull-right"
                                onClick={() => {

                                    this.props.logOut();

                                }}
                            >OK</Button>



                        </Card.Body>
                    </Card>

                </Drawer>


                <Drawer

                    open={this.props.isShowProcessDialog}
                    onRequestClose={this.toggle}
                    direction='left'
                >

                    <Card >


                        <Card.Body className="pl-3 pr-3">

                            <div style={{ fontSize: 18, marginLeft: 20, color: "red", fontWeight: "bold", textAlign: "center" }}>
                                {this.props.noOfcompletedData}
                                <span style={{ fontSize: 18, color: "black" }}> of </span>
                                {this.props.noOfDataGoingToProcess}
                                <span style={{ fontSize: 18, color: "black" }}> is Completed Successfully </span>
                            </div>
                            <Undraw
                                style={{ marginTop: 10, display: 'block', width: 461, marginBottom: 10, height: 263 }}
                                name={this.props.noOfcompletedData == this.props.noOfDataGoingToProcess ? "new-message" : "design-process"} />

                            {this.showOkBtnInProcessing()}





                        </Card.Body>
                    </Card>

                </Drawer>


                <Drawer

                    open={this.props.isShowUnProcessedDataListDialog}
                    onRequestClose={this.toggle}
                    direction='left'
                >

                    <Card >


                        <Card.Body className="pl-3 pr-3">
                            <div style={{ fontSize: 18, fontWeight: "bold", color: "red" }}>Unable to process this STB List:  </div>
<div>No of STB : <span style={{ color:"red",marginTop:10,fontSize:17,marginBottom:10 }}>     {this.props.unProcessedData.length} </span></div>

                            <div style={{ marginLeft: 50, maxHeight: "30vh", marginRight: 30, color: "rgb(26, 172, 195)", overflow: "auto", }}>
                                {/* {this.props.unProcessedData.map((data, i) => {
                                    return (
                                        <div style={{ fontSize: 15, marginTop: 5, fontWeight: "bold" }}> 
                                        {i + 1}.)  {data.result}  <span style={{ color: "red" }}>{data.STBNum}</span>
                                        </div>
                                    )
                                })} */}
                                 <table className="table table-striped">
                       
                        <thead>
                          

                            <th>STB</th>
                            <th>Error</th>
                           




                        </thead>
                        <tbody className="total-records">
                        {this.props.unProcessedData.map((data, i) => {
                                    return (
                                        <tr>
                                      



                                        <td>{data.STBNum}</td>
                                        <td>{data.result}</td>
                                        



                                    </tr>
                                    )
                                })}
                           
                        </tbody>
                    </table>
                                 
                            </div>


                            <Button
                                style={{ backgroundColor: "green", borderColor: "green", marginRight: 15 }}
                                className="mt-3 pull-right"
                                onClick={() => {

                                    this.props.hindUnProcessedDataListDialog();

                                }}
                            >OK</Button>



                        </Card.Body>
                    </Card>


                </Drawer>

                <Drawer

                    open={this.props.isShowCreateWarning}
                    onRequestClose={this.toggle}
                    direction='left'
                >

                    <Card >


                        <Card.Body className="pl-3 pr-3">
                            <div style={{ fontSize: 18, }}>Do you want <span style={{ color: "red", fontWeight: "bold" }}>Create Customer</span> ?</div>
                            <div style={{ marginTop: 15 }}>Number of STBs : <span style={{ color: "red", fontWeight: "bold" }}>{this.props.noOfDataGoingToProcessforRef}</span></div>
                            <Undraw className="undrawimg" name="add-user" primaryColor="green" />

                            <Button
                                style={{ backgroundColor: "red", borderColor: "red" }}
                                className="mt-3 pull-right"
                                onClick={() => {

                                    this.props.hindCreateWarning();

                                }}
                            >cancel</Button>
                            <Button
                                style={{ backgroundColor: "green", borderColor: "green", marginRight: 15 }}
                                className="mt-3 pull-right"
                                onClick={() => {
                                    this.props.hindCreateWarning();
                                    this.props.CreateFuncCSV();

                                }}
                            >OK</Button>



                        </Card.Body>
                    </Card>

                </Drawer>


                <Drawer

                    open={this.props.isEmptyList}
                    onRequestClose={this.toggle}
                    direction='left'
                >

                    <Card >


                        <Card.Body className="pl-3 pr-3">
                            <div style={{ fontSize: 18, fontWeight: "bold", color: "red" }}>Please select STB </div>

                            <Undraw
                                style={{ marginTop: 10, display: 'block', width: 461, marginBottom: 10, height: 263 }}
                                name="empty" />


                            <Button
                                style={{ backgroundColor: "green", borderColor: "green", marginRight: 15 }}
                                className="mt-3 pull-right"
                                onClick={() => {

                                    this.props.hindEmptyList();

                                }}
                            >OK</Button>



                        </Card.Body>
                    </Card>

                </Drawer>

                <Drawer

                    open={this.props.isShowMantainanceDialog}
                    onRequestClose={this.toggle}
                    direction='left'
                >

                    <Card >


                        <Card.Body className="pl-3 ">


                            <label style={{ fontSize: 20, fontWeight: "bold", textAlign: "center", marginLeft: 20 }}>{this.props.maintText} </label>
                            <div style={{ width: 350, height: 250, marginBottom: 30 }}>
                                <img

                                    style={{ width: 350, height: 250 }}
                                    src={this.props.maintIMG} className="spinnerContent" color="green" />
                            </div>
                            <div style={{ display: "block", textAlign: "center", width: "100%" }}>
                                <Button
                                    style={{ backgroundColor: "#E64A19", borderColor: "#E64A19" }}
                                    // className="mt-3 pull-right"
                                    onClick={() => {
                                        this.props.getLOC_Details();


                                    }}
                                >Refresh</Button>

                            </div>

                        </Card.Body>
                    </Card>

                </Drawer>











            </div>
        );
    }
}