import React from 'react';
import Drawer from 'react-drag-drawer';
import './index.css';
import Undraw from 'react-undraw';
import { Button, ButtonGroup, Container, ButtonToolbar, Jumbotron, Card } from 'react-bootstrap';
export default class DialogBoxs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedLco: {},
            isShowTransferWarning: false,
            STBList: "",
            isShowLowBalance: false
        }
    }
    showOkBtnInProcessing() {
        if (this.props.noOfcompletedData == this.props.noOfDataGoingToProcess) {
            return (
                <Button
                    style={{ backgroundColor: this.props.unProcessedData.length ? "red" : "green", borderColor: "green", marginRight: 15 }}
                    className="mt-3 pull-right"
                    onClick={() => {

                        this.props.hindProcessDialog();

                    }}
                >{this.props.unProcessedData.length ? "Show Unprocess STB" : "OK"}</Button>
            );
        } else {
            return null;
        }
    }
    render() {
        return (
            <div>


                <Drawer

                    open={this.props.isShowAddPackageWarning}
                    onRequestClose={this.toggle}
                    direction='left'
                >

                    <Card >


                        <Card.Body className="pl-3 pr-3">
                            <div style={{ fontSize: 18, fontWeight: "bold" }}>Do you want <span style={{ color: "red", fontWeight: "bold" }}>add package </span>?</div>
                            <div style={{ marginTop: 10, marginBottom: 10, fontSize: 18 }}>Total Amount :
                            <span style={{ color: "red", fontWeight: "bold" }}>{this.props.proDataValue}</span>
                                <span style={{ color: "gray", }}>+ (Tax amount)</span>

                            </div>
                            <Undraw
                                style={{ marginTop: 10, display: 'block', width: 461, marginBottom: 10, height: 263 }}
                                name="add-files" />
                            <div style={{ textAlign: "end" }}>

                                <Button
                                    style={{ marginRight: 15, backgroundColor: "red" }}
                                    className="danger" className="mt-3 pull-right"
                                    onClick={() => {

                                        this.props.hindAddPackageWarning();


                                    }}
                                >Cancel</Button>
                                <Button

                                    style={{ marginRight: 15, backgroundColor: "green" }}
                                    className="success" className="mt-3 pull-right"
                                    onClick={() => {

                                        this.props.hindAddPackageWarning();
                                        console.log("price",this.props.lcoBalance,this.props.proDataValue )
                                        // this.props.addPackage();
                                        debugger;
                                        if (parseFloat(this.props.lcoBalance) > parseFloat(this.props.proDataValue)) {
                                            this.props.addPackage();
                                        } else {
                                            this.setState({ isShowLowBalance: true })
                                        }
                                        debugger;

                                    }}
                                >OK</Button>
                            </div>




                        </Card.Body>
                    </Card>

                </Drawer>


                <Drawer

                    open={this.props.isEmptyList}
                    onRequestClose={this.toggle}
                    direction='left'
                >

                    <Card >


                        <Card.Body className="pl-3 pr-3">
                            <div style={{ fontSize: 18, fontWeight: "bold", color: "red" }}>Please select STB </div>

                            <Undraw
                                style={{ marginTop: 10, display: 'block', width: 461, marginBottom: 10, height: 263 }}
                                name="empty" />


                            <Button
                                style={{ backgroundColor: "green", borderColor: "green", marginRight: 15 }}
                                className="mt-3 pull-right"
                                onClick={() => {

                                    this.props.hindEmptyList();

                                }}
                            >OK</Button>



                        </Card.Body>
                    </Card>

                </Drawer>


                <Drawer

                    open={this.state.isShowLowBalance}
                    onRequestClose={this.toggle}
                    direction='left'
                >

                    <Card >


                        <Card.Body className="pl-3 pr-3">
                            <div style={{ fontSize: 18, fontWeight: "bold", color: "red" }}>Low Balance </div>

                            <Undraw
                                style={{ marginTop: 10, display: 'block', width: 461, marginBottom: 10, height: 263 }}
                                name="warning" primaryColor="red" />


                            <Button
                                style={{ backgroundColor: "green", borderColor: "green", marginRight: 15 }}
                                className="mt-3 pull-right"
                                onClick={() => {

                                    this.setState({ isShowLowBalance: false })

                                }}
                            >OK</Button>



                        </Card.Body>
                    </Card>

                </Drawer>












            </div>
        );
    }
}