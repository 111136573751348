import styled from 'styled-components';

export const Rootcontainer = styled.div `
width:100%;

`
export const RootDiv=styled.div `
width: 100%;
display: table;
padding: 3px 27px;


`
export const SelectedDiv = styled.div `
    width: 100%;
    margin-right: 0px;
    margin-bottom: 7px;
    `

export const SelectedPackage = styled.label `
background-color: #a9d484;
color: #ffffff;
font-weight: 600;
padding: 7px 11px;
border-radius: 21px;
font-size: 15px
`
export const MainContainer = styled.div `
padding:3px 21px;
width:100%;
background:#ffffff;
float: left;

`

export const RootTitle=styled.label `
float:left;    font-size:17px;
font-weight: 600;margin-bottom: 0px;
`

export const RootBal=styled.label `
float:right;    font-size:17px;
font-weight: 600;margin-bottom: 0px;
`

export const RootLeft=styled.div `
float: left;
`

export const RootRight = styled.div `
float:right;
`

export const RootValue = styled.label `
    color:#1bcd56;
    font-size: 17px;
    font-weight: 600;margin-bottom: 0px;
    `
    export const FooterUl = styled.ul ` 
    position: fixed;
    bottom: 0;
    background: #ffffff;
    list-style:none;
    width: 100%;
    `
    export const FooterLi = styled.li ` 
    display:inline-block;
    
    `

