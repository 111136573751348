import styled from 'styled-components';

export const Rootcontainer = styled.div `
width:100%;

`
export const RootDiv=styled.div `
width: 100%;
display: table;
padding: 3px 27px;


`

export const RootTitle=styled.label `
float:left;    font-size:17px;
font-weight: 600;margin-bottom: 0px;
`

export const RootBal=styled.label `
float:right;    font-size:17px;
font-weight: 600;margin-bottom: 0px;
`

export const RootLeft=styled.div `
float: left;
`

export const RootRight = styled.div `
float:right;
`
export const MainContainer = styled.div `
padding:3px 21px;
width:100%;
background:#ffffff;
float: left;

`
export const SearchDiv = styled.div `
background:#bfec99;
width:100%;
padding: 7px 47px;
border-radius: 37px;
position:relative;
&:focus
{
    background-color:yellow;
}
`

export const FaSearch = styled.div `
position: absolute;
left: 21px;
top: 17px;

`
export const RootValue = styled.label `
    color:#1bcd56;
    font-size: 17px;
    font-weight: 600;margin-bottom: 0px;
    `
    export const FooterUl = styled.ul ` 
    position: fixed;
    bottom: 0;
    background: #ffffff;
    list-style:none;
    width: 100%;
    `
    export const FooterLi = styled.li ` 
    display:inline-block;
    
    `

// export const Rootcontainer = styled.div `
// width:100%;
// top:0;
// bottom:0;
// left:0;
// right:0;
// background:red;
// margin:0;

// `
// export const SearchDiv = styled.div `
// background:#bfec99;
// width:100%;
// padding: 7px 47px;
// border-radius: 37px;
// position:relative;
// float: left;

// `
// export const MainContainer = styled.div `
// padding:3px 21px;
// width:100%;
// background:#ffffff;
// float: left;

// `

export const CustomerStatus = styled.label `
color: #69e500;
font-size: 17px;
font-weight: 600;
`

export const CustomerStatusDiv = styled.div `
display: block;
text-align: right;

`

export const Amt = styled.label `
float:right;
margin-top: 0px;
color: red;
font-size: 23px!important;
margin-right: 11px;
`

export const MoreDetails = styled.a `
text-align: center;
width: 100%;
display: block;
margin-top: 11px;
color: #7cd035!important;
cursor:pointer;

`

export const BtnGroupRight = styled.label `
float:right;
`

export const STBGroup = styled.div `
width:100%;
float:left;
`

export const STBDetails = styled.div `
width: 100%;
float: left;
border: 1px solid gainsboro;
margin: 11px 0px;
padding: 11px 11px;
`

export const ActivPackage = styled.label `
color: #3cd43c;
font-size: 21px;
font-weight:600;
`

export const ChannelBox = styled.div `
background-color: #e2ffd8;
padding: 7px;
line-height: 2;
font-weight: 600;
margin: 0px 0px 11px 0px;
box-shadow: 0px 1px 3px 0px gainsboro;
-webkit-box-shadow: 0px 1px 3px 0px gainsboro;
`
export const ChannelPrice = styled.div `
font-size: 21px;
text-align: center;    color: #ff6d6d;
`

export const ChannelName = styled.div `
font-size: 17px;
text-align: center;
`


// export const RootDiv=styled.div `
// width: 100%;
// display: block;
// padding: 3px 27px;
// float: left;

// `


// export const RootLeft=styled.div `
// float: left;
// `

// export const RootRight = styled.div `
// float:right;
// `


// export const RootTitle=styled.label `
// float:left;    font-size: 21px;
// font-weight: 600;margin-bottom: 0px;
// `

// export const RootBal=styled.label `
// float:right;    font-size: 21px;
// font-weight: 600;margin-bottom: 0px;
// `


// export const RootValue = styled.label `
//     color:#1bcd56;
//     font-size: 21px;
//     font-weight: 600;margin-bottom: 0px;
//     `
    export const AddPackageHeading = styled.h3 `
    margin-bottom: 0px;
    padding: 7px;
    font-size: 23px;
    line-height: 2.3;
    border: 0px solid #ececec;
    text-align: center;
    `