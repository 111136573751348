import React from "react";
import Drawer from "react-drag-drawer";
import "./index.css";
import {
    Button,
    ButtonGroup,
    Container,
    ButtonToolbar,
    Jumbotron,
    Card
} from "react-bootstrap";
import {
    MdArrowDownward,
    MdFileDownload
} from 'react-icons/md';
import { CSVLink } from 'react-csv';

import Undraw from "react-undraw";

export default class DialogBoxs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            view360Data: this.props.View360Data,
            isShowTransferDailog: false,
            isShowProductLogsDialog: false,
            isShowSettopLogsDialog: false,
            isShowInvoicesDialog: false,
            isShowHistoryDialog: false

        }
        console.log("Transer", this.props.view360Data);
    }
    invoiceLogsHeader = [
        { label: "Customernum", key: "Customernum" },
        { label: "Lcocode", key: "Lcocode" },
        { label: "Lconame", key: "Lconame" },
        { label: "amount", key: "amount" },
        { label: "taxamount", key: "taxamount" },
        { label: "totalamount", key: "totalamount" },
        { label: "agent", key: "agent" },
        { label: "Product", key: "Product" },
        { label: "comment", key: "comment" },


    ];
    settopHeader = [
        { label: "Product", key: "Product" },
        { label: "Lcocode", key: "Lcocode" },
        { label: "Lconame", key: "Lconame" },
        { label: "created", key: "created" },
        { label: "action", key: "action" },
        { label: "message", key: "message" },
        { label: "user", key: "user" },
        { label: "status", key: "status" },
        { label: "exception", key: "exception" }

    ];
    ProductLogsHeader = [
        { label: "Product", key: "Product" },
        { label: "Lcocode", key: "Lcocode" },
        { label: "Lconame", key: "Lconame" },
        { label: "Start", key: "Start" },
        { label: "End", key: "End" },
    ];
    histroyLogsHeader = [

        { label: "SNo", key: "SNo" },
        { label: "MACVCChipNumber", key: "MACVCChipNumber" },
        { label: "history_user", key: "history_user" },
        { label: "history_date", key: "history_date" },
        { label: "Model", key: "Model" },
        { label: "InstallDate", key: "InstallDate" },
        { label: "InstallRate", key: "InstallRate" },
        { label: "Supplier", key: "Supplier" },
        { label: "Lcocode", key: "Lcocode" },
        { label: "connectionstatus", key: "connectionstatus" },
        { label: "Customer", key: "Customer" },
        { label: "AssignDate", key: "AssignDate" },
        { label: "DeactivateDate", key: "DeactivateDate" }


    ];
    thStyle = {
        fontSize: 12
    }


    isShowTransferList() {

        if (this.props.transferHistory) {


            return (
                <div

                    style={{ width: 400, overflow: "auto", height: 400, textAlign: "center" }}>
                    {this.props.transferHistory.map((item, key) => {
                        var dat = new Date(item.created);
                        var mon = dat.getMonth() + 1;
                        var day = dat.getDate();
                        var year = dat.getFullYear();
                        var time = dat.getTime();
                        return (
                            <div  >
                                {key != 0 ? <MdArrowDownward style={{ height: 140, width: 70 }} /> : null}
                                <div> <label> Date :</label><label style={{ fontWeight: "bold", color: "#f44336" }}>{day}-{mon}-{year}</label></div>
                                <div> <label> Time :</label><label style={{ fontWeight: "bold", color: "#f44336" }}>{dat.getHours()}:{dat.getMinutes()}</label></div>
                                <div> <label> From :</label>{item.inlco ? item.inlco : "Null"}</div>
                                <div> <label> To :</label>{item.outlco ? item.outlco : "Null"}</div>

                            </div>
                        );
                    })}
                </div>
            );
        } else {
            return null;
        }
    }
    isShowProductLogFunc() {

        if (this.props.productLogs) {


            return (
                <table className="table table-striped">
                    <thead>
                        <th style={this.thStyle}>Product</th>
                        <th style={this.thStyle}>Lcocode</th>
                        <th style={this.thStyle}>Lconame</th>
                        <th style={this.thStyle}>Start</th>
                        <th style={this.thStyle}>End</th>

                    </thead>
                    <tbody className="total-records">
                        {this.props.productLogs.map((item, key) => {

                            return (
                                <tr>
                                    <td>{item.product}</td>
                                    <td>{item.Lcocode}</td>
                                    <td>{item.Lconame}</td>
                                    <td>{item.start}</td>
                                    <td>{item.end}</td>

                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            );
        } else {
            return null;
        }
    }

    isShowSettopLogsFunc() {

        if (this.props.settoplogs) {


            return (
                <table className="table table-striped">
                    <thead>
                        <th style={this.thStyle}>Product</th>
                        <th style={this.thStyle}>Lcocode</th>
                        <th style={this.thStyle}>Lconame</th>
                        <th style={this.thStyle}>Created</th>
                        <th style={this.thStyle}>Action</th>
                        <th style={this.thStyle}>Message</th>
                        <th style={this.thStyle}>User</th>
                        <th style={this.thStyle}>Status</th>
                        <th style={this.thStyle}>Exception</th>

                    </thead>
                    <tbody className="total-records">
                        {this.props.settoplogs.map((item, key) => {

                            return (
                                <tr>
                                    <td>{item.product}</td>
                                    <td>{item.Lcocode}</td>
                                    <td>{item.Lconame}</td>
                                    <td>{item.created}</td>
                                    <td>{item.action}</td>
                                    <td>{item.message}</td>
                                    <td>{item.user}</td>
                                    <td>{item.status}</td>
                                    <td style={{ color: "red" }}>{item.exception}</td>

                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            );
        } else {
            return null;
        }
    }
    isShowInvoiceLogsFunc() {

        if (this.props.invoiceLogs) {


            return (
                <table className="table table-striped">
                    <thead>
                        <th style={this.thStyle}>Customer no</th>

                        <th style={this.thStyle}>Lcocode</th>
                        <th style={this.thStyle}>Lconame</th>
                        <th style={this.thStyle}>amount</th>
                        <th style={this.thStyle}>Tax amount</th>
                        <th style={this.thStyle}>Total amount</th>
                        <th style={this.thStyle}>Treated</th>
                        <th style={this.thStyle}>Agent</th>
                        <th style={this.thStyle}>Products</th>
                        <th style={this.thStyle}>Comment</th>

                    </thead>
                    <tbody className="total-records">
                        {this.props.invoiceLogs.map((item, key) => {

                            return (
                                <tr>
                                    <td>{item.Customernum}</td>

                                    <td>{item.Lcocode}</td>
                                    <td>{item.Lconame}</td>
                                    <td>{item.amount}</td>
                                    <td>{item.taxamount}</td>
                                    <td>{item.totalamount}</td>
                                    <td>{item.created}</td>
                                    <td style={{ color: "red" }}>{item.agent ? item.agent : "Null"}</td>
                                    <td>{item.Product ? item.Product : "Null"}</td>
                                    <td>{item.comment}</td>

                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            );
        } else {
            return null;
        }
    }

    isShowHistoryLogsFunc() {

        if (this.props.histroyLogs) {


            return (
                <table className="table table-striped">
                    <thead>
                        <th style={this.thStyle}>STB</th>
                        <th style={this.thStyle}>VC</th>
                        <th style={this.thStyle}>History User</th>
                        <th style={this.thStyle}>History Date</th>
                        <th style={this.thStyle}>Model</th>
                        <th style={this.thStyle}>Install Date</th>
                        <th style={this.thStyle}>Install Rate</th>
                        <th style={this.thStyle}>Supplier</th>
                        <th style={this.thStyle}>Lcocode</th>
                        <th style={this.thStyle}>connection status</th>
                        <th style={this.thStyle}>Customer</th>
                        <th style={this.thStyle}>Assign Date</th>
                        <th style={this.thStyle}>Deactivate Date</th>

                    </thead>
                    <tbody className="total-records">
                        {this.props.histroyLogs.map((item, key) => {

                            return (
                                <tr>
                                    <td style={{ fontSize: 13 }}>{item.SNo}</td>
                                    <td style={{ fontSize: 13 }}>{item.MACVCChipNumber}</td>
                                    <td>{item.history_user ? item.history_user : "Null"}</td>
                                    <td style={{ fontSize: 13 }}>{item.history_date}</td>
                                    <td>{item.Model}</td>
                                    <td style={{ fontSize: 13 }}>{item.InstallDate}</td>
                                    <td>{item.InstallRate}</td>
                                    <td style={{ color: "red" }}>{item.Supplier ? item.Supplier : "Null"}</td>
                                    <td>{item.Lcocode ? item.Lcocode : "Null"}</td>
                                    <td>{item.connectionstatus}</td>
                                    <td>{item.Customer}</td>
                                    <td>{item.AssignDate}</td>
                                    <td>{item.DeactivateDate}</td>

                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            );
        } else {
            return null;
        }
    }

    render() {

        return (
            <div>
                <Drawer
                    open={this.props.isShow360ContainerDialog}
                    onRequestClose={this.toggle}
                    direction="left"
                >
                    <Card>
                        <Card.Body className="pl-3 pr-3">
                            <div style={{ fontSize: 20, fontWeight: "bold" }}>360 &#8451; View</div>
                            <div style={{ textAlign: "center" }}>
                                <Undraw
                                    // primaryColor="green"
                                    style={{ width: 120, height: 80, marginTop: 15, marginBottom: 15, }}
                                    className="medicine" name="finance" />
                            </div>
                            <div>
                                <ul typeof="desc" style={{ width: 400 }}>
                                    <li
                                        style={{ borderBottomColor: "gray", marginTop: 10, cursor: "pointer" }}
                                        onClick={() => { this.setState({ isShowProductLogsDialog: true }) }}
                                    >Productlogs</li>
                                    <li
                                        style={{ borderBottomColor: "gray", marginTop: 10, cursor: "pointer" }}
                                        onClick={() => { this.setState({ isShowSettopLogsDialog: true }) }}
                                    >Settoplogs</li>
                                    <li
                                        style={{ borderBottomColor: "gray", marginTop: 10, cursor: "pointer" }}
                                        onClick={() => { this.setState({ isShowInvoicesDialog: true }) }}
                                    >Invoices</li>
                                    <li
                                        style={{ borderBottomColor: "gray", marginTop: 10, cursor: "pointer" }}
                                        onClick={() => { this.setState({ isShowTransferDailog: true }) }}
                                    >Transfer</li>
                                    <li
                                        style={{ borderBottomColor: "gray", marginTop: 10, cursor: "pointer" }}
                                        onClick={() => { this.setState({ isShowHistoryDialog: true }) }}
                                    >History</li>

                                </ul>

                            </div>

                            <div style={{ textAlign: "center", width: 400 }}>
                                <Button
                                    style={{ backgroundColor: "red", borderColor: "red" }}
                                    className="mt-3 pull-right"
                                    onClick={() => {
                                        this.props.hind360ContainerDialog();
                                    }}
                                >
                                    cancel
              </Button>
                            </div>



                        </Card.Body>
                    </Card>
                </Drawer>

                <Drawer
                    open={this.state.isShowTransferDailog}
                    onRequestClose={this.toggle}
                    direction="left"
                >
                    <Card>
                        <Card.Body className="pl-3 pr-3">
                            <div style={{ fontSize: 20, fontWeight: "bold" }}>Transfer History</div>
                            <div style={{ textAlign: "center" }}>
                                <Undraw
                                    // primaryColor="green"
                                    style={{ width: 120, height: 80, marginTop: 15, marginBottom: 15, }}
                                    className="medicine" name="collaboration" />
                            </div>
                            <div>
                                {this.isShowTransferList()}

                            </div>

                            <div style={{ textAlign: "center", width: 400 }}>
                                <Button
                                    style={{ backgroundColor: "red", borderColor: "red" }}
                                    className="mt-3 pull-right"
                                    onClick={() => { this.setState({ isShowTransferDailog: false }) }}
                                >
                                    cancel
              </Button>
                            </div>



                        </Card.Body>
                    </Card>
                </Drawer>

                <Drawer
                    open={this.state.isShowProductLogsDialog}
                    onRequestClose={this.toggle}
                    direction="left"
                >
                    <Card>
                        <Card.Body className="pl-3 pr-3">
                            <div style={{ fontSize: 20, fontWeight: "bold", textAlign: "center" }}>product Logs</div>

                            <div style={{ textAlign: "center" }}>
                                <Undraw
                                    // primaryColor="green"
                                    style={{ width: 120, height: 80, marginTop: 15, marginBottom: 15, }}
                                    className="medicine" name="account" />
                            </div>
                            <div>
                                {this.isShowProductLogFunc()}

                            </div>

                            <div style={{ textAlign: "left", marginLeft: 15 }}>

                                <Button
                                    style={{ backgroundColor: "red", borderColor: "red", marginTop: 20 }}
                                    className="mt-3 pull-right"
                                    onClick={() => { this.setState({ isShowProductLogsDialog: false }) }}
                                >
                                    cancel
              </Button>
                                <CSVLink
                                    headers={this.ProductLogsHeader}
                                    data={this.props.productLogs}
                                    className="mt-3 pull-right"
                                    filename={"ProductLogs.csv"}
                                    style={{ marginRight: 15, marginTop: 40 }}

                                ><MdFileDownload /> Download</CSVLink>



                            </div>



                        </Card.Body>
                    </Card>
                </Drawer>


                <Drawer
                    open={this.state.isShowSettopLogsDialog}
                    onRequestClose={this.toggle}
                    direction="left"
                >
                    <Card>
                        <Card.Body className="pl-3 pr-3">
                            <div style={{ fontSize: 20, fontWeight: "bold", textAlign: "center" }}>Settop Logs</div>

                            <div style={{ textAlign: "center" }}>
                                <Undraw
                                    // primaryColor="green"
                                    style={{ width: 120, height: 80, marginTop: 15, marginBottom: 15, }}
                                    className="medicine" name="accept-request" />
                            </div>
                            <div>
                                {this.isShowSettopLogsFunc()}

                            </div>

                            <div style={{ textAlign: "left", marginLeft: 15 }}>
                                <Button
                                    style={{ backgroundColor: "red", borderColor: "red", marginRight: 20 }}
                                    className="mt-3 pull-right"
                                    onClick={() => { this.setState({ isShowSettopLogsDialog: false }) }}
                                >
                                    cancel
              </Button>
                                <CSVLink
                                    headers={this.settopHeader}
                                    data={this.props.settoplogs}
                                    className="mt-3 pull-right"
                                    filename={"SettopLogs.csv"}
                                    style={{ marginRight: 15, marginTop: 40 }}

                                ><MdFileDownload /> Download</CSVLink>




                            </div>



                        </Card.Body>
                    </Card>
                </Drawer>
                <Drawer
                    open={this.state.isShowInvoicesDialog}
                    onRequestClose={this.toggle}
                    direction="left"
                >
                    <Card>
                        <Card.Body className="pl-3 pr-3">
                            <div style={{ fontSize: 20, fontWeight: "bold", textAlign: "center" }}>Invoice Logs</div>


                            <div style={{ textAlign: "center" }}>
                                <Undraw
                                    // primaryColor="green"
                                    style={{ width: 120, height: 80, marginTop: 15, marginBottom: 15, }}
                                    className="medicine" name="printing-invoices" />
                            </div>
                            <div>
                                {this.isShowInvoiceLogsFunc()}

                            </div>

                            <div style={{ textAlign: "left", marginLeft: 15 }}>
                                <Button
                                    style={{ backgroundColor: "red", borderColor: "red", marginRight: 20 }}
                                    className="mt-3 pull-right"
                                    onClick={() => { this.setState({ isShowInvoicesDialog: false }) }}
                                >
                                    cancel
              </Button>
                                <CSVLink
                                    headers={this.invoiceLogsHeader}
                                    data={this.props.invoiceLogs}
                                    className="mt-3 pull-right"
                                    filename={"InvoiceLogs.csv"}
                                    style={{ marginRight: 15, marginTop: 40 }}

                                ><MdFileDownload /> Download</CSVLink>




                            </div>



                        </Card.Body>
                    </Card>
                </Drawer>

                <Drawer
                    open={this.state.isShowHistoryDialog}
                    onRequestClose={this.toggle}
                    direction="left"
                >
                    <Card>
                        <Card.Body className="pl-3 pr-3">
                            <div style={{ fontSize: 20, fontWeight: "bold", textAlign: "center" }}>Histroy Logs</div>


                            <div style={{ textAlign: "center" }}>
                                <Undraw
                                    // primaryColor="green"
                                    style={{ width: 120, height: 80, marginTop: 15, marginBottom: 15, }}
                                    className="medicine" name="transfer-files" />
                            </div>
                            <div>
                                {this.isShowHistoryLogsFunc()}

                            </div>

                            <div style={{ textAlign: "left", marginLeft: 15 }}>
                                <Button
                                    style={{ backgroundColor: "red", borderColor: "red", marginRight: 20 }}
                                    className="mt-3 pull-right"
                                    onClick={() => { this.setState({ isShowHistoryDialog: false }) }}
                                >
                                    cancel
              </Button>
                                <CSVLink
                                    headers={this.histroyLogsHeader}
                                    data={this.props.histroyLogs}
                                    className="mt-3 pull-right"
                                    filename={"HistoryLogs.csv"}
                                    style={{ marginRight: 15, marginTop: 40 }}

                                ><MdFileDownload /> Download</CSVLink>




                            </div>



                        </Card.Body>
                    </Card>
                </Drawer>









            </div >
        );
    }
}
