import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import SplashScreen from './components/screens/splash';
import LoginForm from './components/screens/login';
import Dashboard from './components/screens/dashboard';
import CustomerSearch from './components/screens/CustomerSearch';
import CustomerDetails from './components/screens/CustomerDetails';
import BottomNavScreen from './components/screens/BottomNavScreen';
import SettingsScreens from './components/screens/Settings';
import CreateCustomer from './components/screens/CreateCustomer';
import UnmappedSTB from './components/screens/UnmappedSTB';
import CustomerAddPack from './components/screens/CustomerAddPackage';

ReactDOM.render(<SplashScreen />, document.getElementById('root'));






// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
