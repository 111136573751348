import styled from "styled-components";

export const MainContainer = styled.div`
  width: 100%;
  float: left;
  padding: 3px 27px;
`;
export const LoadContent = styled.div`
  width: 100%;
  float: left;
`;

export const NavDiv = styled.div`
  width: 100%;
  font-size: 15px;
  font-weight: 600;
  text-align: center;
  display: flex;
  height: 100%;
  box-shadow: 3px 3px 3px 3px gainsboro;
  -webkit-box-shadow: 3px 3px 3px 3px gainsboro;
`;

export const NavHome = styled.div`
  flex: 1;
  position: relative;
  background-color: ${props => props.color};
`;
export const NavSettings = styled.div`
  flex: 1;
  position: relative;
  background-color: ${props => props.color};
`;
export const NavCustomer = styled.div`
  flex: 1;
  position: relative;
  background-color: ${props => props.color};
`;
export const NavSearch = styled.div`
  flex: 1;
  position: relative;
  background-color: ${props => props.color};
`;

export const NavLabel = styled.label`
  position: relative;
  top: 11px;
  font-weight: 600;
`;

export const NavTransactions = styled.div`
  flex: 1;
  position: relative;
  background-color: ${props => props.color};
`;

export const NavBulkOperations = styled.div`
  flex: 1;
  position: relative;
  background-color: ${props => props.color};
`;
export const NavStockOperation = styled.div`
  flex: 1;
  position: relative;
  background-color: ${props => props.color};
`;

export const NavUnpaidSTB = styled.div`
  flex: 1;
  position: relative;
  background-color: ${props => props.color};
`;
