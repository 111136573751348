import React, { useRef } from "react";
import ReactToPrint from "react-to-print";

import Drawer from "react-drag-drawer";
import "./index.css";
import { MdCloudDownload } from "react-icons/md";
import {
  Button,
  ButtonGroup,
  Container,
  ButtonToolbar,
  Jumbotron,
  Card
} from "react-bootstrap";
export default class DialogBoxs extends React.Component {
  isShowDuplicationDialogfun(duplicationData) {
    if (duplicationData != null) {
      return (
        <Drawer
          open={this.props.isShowDuplicationDialog}
          onRequestClose={this.toggle}
          direction="left"
        >
          <Card style={{ overFlow: "auto" }}>
            <Card.Body style={{ overFlow: "auto" }} className="pl-3 pr-3">
              <div style={{ overflow: "auto", height: 500 }}>
                <label
                  style={{ fontSize: 18, fontWeight: "bold", color: "#d61818" }}
                >
                  Duplication Channels List:
                </label>
                {duplicationData.map((item, i) => {
                  return (
                    <div
                      style={{
                        fontSize: 15,
                        marginLeft: 40,
                        color: "rgb(26, 172, 195)"
                      }}
                    >
                      - {item}
                    </div>
                  );
                })}
              </div>

              <Button
                style={{
                  marginLeft: 230,
                  marginRight: 10,
                  backgroundColor: "red",
                  marginTop: 40
                }}
                className="success"
                className="mt-3 pull-right"
                onClick={() => {
                  this.props.hindDuplicationDialog();
                }}
              >
                Cancel
              </Button>
            </Card.Body>
          </Card>
        </Drawer>
      );
    } else {
      return null;
    }
  }
  isShowChannelsListDialogfunc(packageList) {
    var num = 0;
    var num = packageList.length;

    // console.log("package list", num, packageList);

    if (packageList != null) {
      return (
        <Drawer
          open={this.props.isShowChannelsListDialog}
          onRequestClose={this.toggle}
          direction="left"
        >
          {/* <Card style={{ height: 700, width: "100vh", overflow: "auto" }}> */}
          <Card style={{ width: "100vh", overflow: "auto" }}>
            <Card.Body className="pl-3 pr-3">
              <div style={{ fontSize: 25, fontWeight: "bold" }}>
                Channels List
              </div>

              {/* <div style={{ elevation: 2, height: 570, margin: "10", width: "100%", overflow: "auto" }}>
                                {packageList.map((item, i) => {
                                    var channels =[];
                                         channels = item.channels;
                                    return (
                                        <div style={{ display: "inline", width: "30%", marginRight: 10, float: "left" }}>
                                            <div style={{ color: "rgb(26, 172, 195)", fontSize: 15, fontWeight: "bold" }}>{item.name}</div>
                                            <div style={{ marginTop: 10, borderColor: "yellow", borderWidth: 1 }}>
                                                {channels.map((channel, i) => {
                                                    return (
                                                        <div style={{ color: "#d61818", fontSize: 13, marginLeft: 20 }}>- {channel}</div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                           */}
              <div
                style={{
                  elevation: 2,
                  height: 400,
                  margin: "10",
                  width: "100%",
                  overflow: "auto"
                }}
              >
                {packageList.map((item, i) => {
                  var channels = [];
                  channels = item.channels;
                  return (
                    <div
                      style={{
                        display: "inline",
                        width: "30%",
                        marginRight: 10,
                        float: "left"
                      }}
                    >
                      <div
                        style={{
                          // color: "rgb(26, 172, 195)",
                          color: "#132275",
                          fontSize: 15,
                          fontWeight: "bold"
                        }}
                      >
                        {item.name}
                      </div>
                      <div
                        style={{
                          marginTop: 10,
                          borderColor: "yellow",
                          borderWidth: 1
                        }}
                      >
                        {channels.map((channel, i) => {
                          return (
                            <div
                              style={{
                                color: "#d61818",
                                fontSize: 13,
                                marginLeft: 20
                              }}
                            >
                              - {channel}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>

              <Button
                style={{
                  marginLeft: 230,
                  marginRight: 10,
                  backgroundColor: "red",
                  marginTop: 40
                }}
                className="success"
                className="mt-3 pull-right"
                onClick={() => {
                  this.props.hindChannelListDialog();
                }}
              >
                Cancel
              </Button>
            </Card.Body>
          </Card>
        </Drawer>
      );
    } else {
      return null;
    }
  }
  render() {
    // console.log("user duplicaton", this.props.packageList);
    var duplicationData1 = [];
    var { duplicationData, packageList } = this.props;
    duplicationData1 = duplicationData;

    return (
      <div>
        {this.isShowDuplicationDialogfun(duplicationData1)}
        {this.isShowChannelsListDialogfunc(packageList)}
      </div>
    );
  }
}
