import React from 'react';
import Drawer from 'react-drag-drawer';
import './index.css';
import { Button, ButtonGroup, Container, ButtonToolbar, Jumbotron, Card } from 'react-bootstrap';

export default class DialogBoxs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            oldPassword: localStorage.getItem("userPassword"),
            enterCorrectPassword: false,
            enteredPassword: "",
            isMissMatchPassword: false,
            newPassword: "",
            confirmNewPassword: "",
            isShowNewPasswordDialog: false
        }
    }
    isCorrectPassword() {
        console.log("data enter check enteredPassword", this.state.enteredPassword);
        console.log("data oldPassword", localStorage.getItem("userPassword"));
        if (this.state.enteredPassword == localStorage.getItem("userPassword")) {
            this.setState({ enterCorrectPassword: false, isShowNewPasswordDialog: true, enteredPassword: "" });
            this.props.hindChangePasswordDialog();
        } else {
            this.setState({ enterCorrectPassword: true });
        }
    }

    isShowWrongPassword() {
        if (this.state.enterCorrectPassword) {
            return (
                <div style={{ color: "red", fontSize: 15, textAlign: "center", fontWeight: "bold", width: 300 }}>Wrong password !</div>
            );
        } else {
            return null
        }
    }
    checkNewAndConfirmSame() {
        if (this.state.newPassword == this.state.confirmNewPassword) {
            this.setState({ isShowNewPasswordDialog: false, isMissMatchPassword: false, newPassword: "", confirmNewPassword: "" });
            this.props.setNewPassword(this.state.newPassword);
        } else {
            this.setState({ isMissMatchPassword: true });
        }
    }

    isShowMissMatchError() {
        if (this.state.isMissMatchPassword) {
            return (
                <div style={{ color: "red", fontSize: 15, textAlign: "center", fontWeight: "bold", width: 300 }}>Miss match!</div>
            );
        } else {
            return null
        }
    }
    render() {
        return (
            <div>


                <Drawer

                    open={this.props.isShowChangePasswordDialog}
                    onRequestClose={this.toggle}
                    direction='left'
                >

                    <Card >


                        <Card.Body className="pl-3 ">

                            <div style={{ color: "rgb(26, 172, 195)", fontSize: 18, textAlign: "center", fontWeight: "bold", width: 300 }}>Change password</div>
                            {this.isShowWrongPassword()}
                            <div style={{ textAlign: "center" }}>
                                <input

                                    style={{
                                        borderBottom: "2px solid rgb(26, 172, 195)",
                                        borderRight: "2px solid rgb(26, 172, 195)",
                                        borderLeft: "2px solid red",
                                        borderTop: "2px solid red",
                                        marginTop: 20,
                                        textAlign: "center",
                                        height: 40,
                                        width: 248
                                    }}
                                    value={this.state.enteredPassword}
                                    onKeyPress={(e) => {

                                        if (e.keyCode == 13 || e.which == 13) {
                                            this.isCorrectPassword();
                                        }
                                    }}
                                    type="text"
                                    placeholder="Enter you old password"
                                    onChange={(e) => { this.setState({ enteredPassword: e.target.value }) }}
                                ></input></div>
                            <Button
                                style={{ backgroundColor: "red", borderColor: "red", marginLeft: 15 }}
                                className="mt-3 pull-right"
                                onClick={() => {
                                    this.props.hindChangePasswordDialog();
                                    this.setState({ enteredPassword: "" })

                                }}
                            >Cancel</Button>
                            <Button

                                className="success" className="mt-3 pull-right"
                                onClick={() => {
                                    this.isCorrectPassword();

                                }}
                            >OK</Button>



                        </Card.Body>
                    </Card>

                </Drawer>


                <Drawer

                    open={this.state.isShowNewPasswordDialog}
                    onRequestClose={this.toggle}
                    direction='left'
                >

                    <Card >


                        <Card.Body className="pl-3 ">

                            <div style={{ color: "rgb(26, 172, 195)", fontSize: 18, textAlign: "center", fontWeight: "bold", width: 300 }}>New password</div>
                            {this.isShowMissMatchError()}
                            <div style={{ textAlign: "center" }}>
                                <input

                                    style={{
                                        borderBottom: "2px solid rgb(26, 172, 195)",
                                        borderRight: "2px solid rgb(26, 172, 195)",
                                        borderLeft: "2px solid red",
                                        borderTop: "2px solid red",
                                        marginTop: 20,
                                        textAlign: "center",
                                        height: 40,
                                        width: 248
                                    }}
                                    value={this.state.newPassword}
                                    onKeyPress={(e) => {

                                        if (e.keyCode == 13 || e.which == 13) {
                                            this.isCorrectPassword();
                                        }
                                    }}
                                    type="password"
                                    placeholder="Enter you new password"
                                    onChange={(e) => { this.setState({ newPassword: e.target.value }) }}
                                ></input></div>

                            <div style={{ textAlign: "center" }}>
                                <input

                                    style={{
                                        borderBottom: "2px solid rgb(26, 172, 195)",
                                        borderRight: "2px solid rgb(26, 172, 195)",
                                        borderLeft: "2px solid red",
                                        borderTop: "2px solid red",
                                        marginTop: 20,
                                        textAlign: "center",
                                        height: 40,
                                        width: 248
                                    }}
                                    value={this.state.confirmNewPassword}
                                    onKeyPress={(e) => {

                                        if (e.keyCode == 13 || e.which == 13) {
                                            this.isCorrectPassword();
                                        }
                                    }}
                                    type="password"
                                    placeholder="Re-type new password"
                                    onChange={(e) => { this.setState({ confirmNewPassword: e.target.value }) }}
                                ></input></div>
                            <Button
                                style={{ backgroundColor: "red", borderColor: "red", marginLeft: 15 }}
                                className="mt-3 pull-right"
                                onClick={() => {
                                    this.setState({ isShowNewPasswordDialog: false, newPassword: "", oldPassword: "", confirmNewPassword: "" });

                                }}
                            >Cancel</Button>
                            <Button

                                className="success" className="mt-3 pull-right"
                                onClick={() => {
                                    this.checkNewAndConfirmSame();

                                }}
                            >OK</Button>



                        </Card.Body>
                    </Card>

                </Drawer>

                <Drawer

                    open={this.props.isShowSuccessPasswordChangedDialog}
                    onRequestClose={this.toggle}
                    direction='left'
                >

                    <Card >


                        <Card.Body className="pl-3 ">

                            <div style={{ color: "green", fontSize: 18, textAlign: "center", fontWeight: "bold", width: 300 }}>Successfully Password changed! <span style={{ color: "red" }}> we log out now</span> </div>



                            <Button

                                className="success" className="mt-3 pull-right"
                                onClick={() => {
                                    this.props.logOut();

                                }}
                            >OK</Button>



                        </Card.Body>
                    </Card>

                </Drawer>

                <Drawer

                    open={this.props.isShowErrorPasswordChangedDialog}
                    onRequestClose={this.toggle}
                    direction='left'
                >

                    <Card >


                        <Card.Body className="pl-3 ">

                            <div style={{ color: "red", fontSize: 18, textAlign: "center", fontWeight: "bold", width: 300 }}>Unable to Change the password !</div>



                            <Button

                                className="success" className="mt-3 pull-right"
                                onClick={() => {
                                    this.props.hindErrorPasswordChangedDialog();

                                }}
                            >OK</Button>



                        </Card.Body>
                    </Card>

                </Drawer>

             


            </div>
        );
    }
}