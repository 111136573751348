export const CAS = {
    NSTV_drm: [
        {
            sno: 1,
            model: "ST9",
            type: "HD",
            cas: "NSTV_drm"
        },
        {
            sno: 2,
            model: "V1 PRO",
            type: "HD",
            cas: "NSTV_drm"
        }

    ],
    Phandos_OTT: [
        {
            sno: 3,
            model: "OTT",
            type: "HD",
            cas: "Phandos_OTT"
        }
    ],
    H8_ISP: [
        {
            sno: 3,
            model: "HD",
            type: "HD",
            cas: "H8_ISP"
        }
    ],
    JAZE_ISP: [
        {
            sno: 4,
            model: "SSPL-702-OTT-GV",
            type: "HD",
            cas: "JAZE_ISP"
        },
        {
            sno: 5,
            model: "ST9",
            type: "HD",
            cas: "JAZE_ISP"
        }
    ]
}