import React from "react";
import Drawer from "react-drag-drawer";
import "../../screens/CustomerDetails/index.css";
import { FaSearch } from "react-icons/fa";
import API from '../../Api';
import { MdClose, MdRemoveCircle } from "react-icons/md";
import {
  Rootcontainer,
  SearchDiv,
  MainContainer,
  RootDiv,
  RootTitle,
  RootBal,
  RootRight,
  Amt,
  MoreDetails,
  RootLeft,
  RootValue,
  CustomerStatus,
  BtnGroupRight,
  STBGroup,
  STBDetails,
  CustomerStatusDiv,
  ActivPackage,
  ChannelBox,
  ChannelName,
  ChannelPrice,
  AddPackageHeading,
  SelectedPackage,
  SelectedDiv,
  BalanceDiv,
  CurrentBal,
  TotalBal,
  AvailableBal
} from "./styled";

import {
  FormControl,
  FormGroup,
  FormLabel,
  Container,
  ControlLabel,
  HelpBlock,
  Checkbox,
  Radio,
  Button,
  InputGroup,
  Form,
  Card,
  Col,
  Row
} from "react-bootstrap";
export default class AddPack extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: props.userMetaData.Email,
      address: props.userMetaData.Address,
      mobileNo: props.userMetaData.Mobile,
      name: props.userMetaData.name,
      isShowOTPDialog: false,
      randomValue:Math.floor(10000 + Math.random() * 90000),
      enteredOTP:"",
      errorMsg:"",
      pincode:"",
      aadhar: props.aadharNo
    };
    console.log(
      "data fsjkhskjdngkj ",
      props.userMetaData.Email,
      props.userMetaData.Address,
      props.userMetaData.Mobile
    );

    this.setState({
      email: props.userMetaData.Email,
      mobileNo: props.userMetaData.Mobile,
      pincode: props.userMetaData.Pincode,
    });
  }
  componentWillMount() {
    console.log(
      "component will ",
      this.props.userMetaData.Email,
      this.props.userMetaData.Mobile,
      this.props.aadharNo
    );
    debugger;
    this.setState({
      email: this.props.userMetaData.Email,
      mobileNo: this.props.userMetaData.Mobile,
      pincode: this.props.userMetaData.Pincode,
      aadhar:this.props.aadharNo
    });
    console.log("usp", this.state.email, this.state.mobileNo);
  }
  toggle = () => {
    let { toggle } = this.state;

    this.setState({ toggle: !toggle });
  };
  sendOTPNumber(){
    this.setState({errorMsg:""})
    var lcoID=localStorage.getItem("userID");
   var lcoPassword= localStorage.getItem("userPassword");
    API.sendOTP(lcoID,lcoPassword,this.state.mobileNo,this.state.randomValue)
    .then(e=>e.json())
    .then(resJson=>{
           console.log("response",resJson);
           debugger;
           if(resJson.response !="ok"){
            this.setState({errorMsg:"Something went wrong"})
           }

    }).catch(e=>{
      console.log("error",e);
      this.setState({errorMsg:"Something went wrong"})
      debugger;
    })
  }
  callEditFunc() {
    this.setState({ isShowWarning: false });
    const { email, name, pincode, address, mobileNo ,aadhar} = this.state;
    this.props.editCustomerFunc(name, address, mobileNo, email, pincode,aadhar);
  }
  isShowWrongOTP(){
    if(this.state.errorMsg){
      return <label style={{width:"100%",color:"red",textAlign:"center"}}>Wrong OTP</label>
    }
    return null;
  }
  checkVerification(){
    if(this.state.randomValue == this.state.enteredOTP){
      this.setState({
        isShowOTPDialog:false,
        randomValue:Math.floor(10000 + Math.random() * 90000),
        enteredOTP:""
      })
      const {
        email,
        name,
        pincode,
        address,
        mobileNo,
        aadhar
      } = this.state; 
      console.log("data",email);
      debugger;
      this.props.editCustomerFunc(
        name,
        address,
        mobileNo,
        email,
        pincode,
        aadhar
      );
    }else{
     this.setState({errorMsg:"Wrong OTP"})
    }
  }
  render() {
    // console.log("user meta data", this.props.userMetaData);
    return (
      <div>
        <Drawer
          open={this.props.isShowCustomerDialog}
          onRequestClose={this.toggle}
          direction="left"
          className="popup-width"
        >
          <div
            style={{
              width: "80vw",
              backgroundColor: "white",
              overflow: "auto"
            }}
          >
            <div
              style={{
                justifyContent: "center",
                marginTop: 20,
                textAlign: "center",
                marginBottom: 20
              }}
            >
              <label
                style={{ fontSize: 23, color: "#d61818", fontWeight: "bold" }}
              >
                Edit Customer
              </label>
             
            </div>
            <center>
            <label
                style={{ fontSize: 15, color: "#d61818", fontWeight: "bold" }}
              >
              {this.state.errorMsg}
              </label>
            </center>
            {/*              
                        <Row style={{ marginTop: 60, marginLeft: 60, marginRight: 60 }}>
                            <Col md={4} sm={12} lg={4}>
                                <label style={{ marginRight: 20, fontSize: 18 }}>Name : </label>
                                <input type="text/number" 
                                    style={{ fontSize: 18, border: "1px solid rgb(2,0,36)" }}
                                    value={this.state.name}
                                    onChange={(e) => { this.setState({ name: e.target.value }) }} ></input>
                            </Col>
                            <Col md={4} sm={12} lg={4}>
                                <label style={{ marginRight: 20, fontSize: 18 }}>Address :</label>
                                <input type="text/number" 
                                multiple
                                    style={{ fontSize: 18, border: "1px solid rgb(2,0,36)" }}
                                    value={this.state.address}
                                    onChange={(e) => { this.setState({ address: e.target.value }) }}></input>
                            </Col>
                            <Col md={4} sm={12} lg={4}>
                                <label style={{ marginRight: 20, fontSize: 18, fontStyle: "NeueHelvetica" }}>Mobile No :</label>
                                <input type="number" 
                                    style={{ fontSize: 18, border: "1px solid rgb(2,0,36)" }}
                                    value={this.state.mobileNo }
                                    onChange={(e) => { this.setState({ mobileNo: e.target.value }) }}
                                ></input>
                            </Col>
                        </Row>

                        <Row style={{ marginTop: 60, marginLeft: 60, marginRight: 60 }}>
                            <Col md={4} sm={12} lg={4}>
                                <label style={{ marginRight: 20, fontSize: 18 }}>Email : </label>
                                <input type="text/number" 
                                    style={{ fontSize: 18, border: "1px solid rgb(2,0,36)" }}
                                    value={this.state.email}
                                    onChange={(e) => { this.setState({ email: e.target.value }) }}
                                ></input>
                            </Col>
                            <Col md={4} sm={12} lg={4}>
                                <label style={{ marginRight: 20, fontSize: 18 }}>Pincode :</label>
                                <input type="text/number" 
                                    style={{ fontSize: 18, border: "1px solid rgb(2,0,36)" }}
                                    value={this.state.pincode}
                                    onChange={(e) => { this.setState({ pincode: e.target.value }) }}
                                ></input>
                            </Col>
                    
                        </Row> */}

            <Row
              id="edit-customer-details"
              style={{ marginTop: 40, marginLeft: 60, marginRight: 60 }}
            >
              <Col md={6} sm={12} lg={6} className="my-3">
                <label
                  style={{ marginRight: 10, fontSize: 18, fontWeight: "bold" }}
                >
                  Name<span style={{color:"red"}}> *</span> 
                </label>
                <input
                  type="text/number"
                  style={{
                    fontSize: 15,
                    // width: "70%",
                    border: "1px solid rgb(103, 103, 103)",
                    padding: "5px 10px",
                    borderRadius: "8px",
                    marginLeft: "20px"
                  }}
                  value={this.state.name}
                  onChange={e => {
                    this.setState({ name: e.target.value });
                  }}
                ></input>
              </Col>

              <Col md={6} sm={12} lg={6} className="my-3">
                <label
                  style={{ marginRight: 10, fontSize: 18, fontWeight: "bold" }}
                >
                  Mobile No<span style={{color:"red"}}> *</span>
                </label>

                <input
                  type="text/number"
                  style={{
                    fontSize: 16,
                    // width: "70%",
                    border: "1px solid rgb(103, 103, 103)",
                    padding: "5px 10px",
                    borderRadius: "8px",
                    marginLeft: "5px"
                  }}
                  value={this.state.mobileNo}
                  onChange={e => {
                    this.setState({ mobileNo: e.target.value });
                  }}
                />
              </Col>
              <Col md={6} sm={12} lg={6} className="my-3">
                <label
                  style={{ marginRight: 10, fontSize: 18, fontWeight: "bold" }}
                >
                   Aadhar No<span style={{color:"red"}}> *</span>
                </label>

                <input
                  type="text/number"
                  style={{
                    fontSize: 16,
                    // width: "70%",
                    border: "1px solid rgb(103, 103, 103)",
                    padding: "5px 10px",
                    borderRadius: "8px",
                    marginLeft: "5px"
                  }}
                  value={this.state.aadhar}
                  onChange={e => {
                    this.setState({ aadhar: e.target.value });
                  }}
                />

              </Col>

              <Col md={6} sm={12} lg={6} className="my-3">
                <label
                  style={{ marginRight: 20, fontSize: 18, fontWeight: "bold" }}
                >
                  Email 
                </label>
                <input
                  type="text/number"
                  style={{
                    fontSize: 16,
                    // width: "70%",
                    border: "1px solid rgb(103, 103, 103)",
                    padding: "5px 10px",
                    borderRadius: "8px",
                    marginLeft: "20px"
                  }}
                  value={this.state.email}
                  onChange={e => {
                    this.setState({ email: e.target.value });
                  }}
                ></input>
              </Col>

              <Col md={6} sm={12} lg={6} className="my-3">
                <label
                  style={{ marginRight: 10, fontSize: 18, fontWeight: "bold" }}
                >
                  Pincode<span style={{color:"red"}}> *</span>
                </label>
                <input
                  type="text/number"
                  style={{
                    fontSize: 16,
                    // width: "70%",
                    border: "1px solid rgb(103, 103, 103)",
                    padding: "5px 10px",
                    borderRadius: "8px",
                    marginLeft: "20px"
                  }}
                  value={this.state.pincode}
                  onChange={e => {
                    this.setState({ pincode: e.target.value });
                  }}
                ></input>
              </Col>
              <Col md={6} sm={12} lg={6} className="my-3 edit-address">
                <label
                  style={{
                    marginRight: 10,
                    fontSize: 18,
                    fontWeight: "bold",
                    verticalAlign: "middle"
                    // marginTop: "-100px"
                  }}
                >
                  Address<span style={{color:"red"}}> *</span>
                </label>
                <textarea
                  rows="5"
                  type="text/number"
                  multiple
                  style={{
                    fontSize: 16,
                    border: "1px solid rgb(103, 103, 103)",
                    padding: "5px 10px",
                    borderRadius: "8px",
                    width: "70%"
                  }}
                  value={this.state.address}
                  onChange={e => {
                    this.setState({ address: e.target.value });
                  }}
                />
              </Col>
            </Row>

            <div
              style={{
                alignSelf: "flex-end",
                textAlign: "end",
                marginTop: "40px",
                marginRight: 15,
                marginBottom: 20
              }}
            >
              <Button
                variant="danger"
                style={{ alignSelf: "flex-start", marginRight: 20 }}
                onClick={() => {
                  this.setState({
                    email: this.props.userMetaData.Email,
                    pincode: this.props.userMetaData.Pincode,
                    address: this.props.userMetaData.Address,
                    mobileNo: this.props.userMetaData.Mobile,
                    name: this.props.userMetaData.name,
                    errorMsg:"",
                    aadhar: this.props.userMetaData.customeridnum 
                  });
                  this.props.hindEditCustomerDialog();
                }}
              >
                Cancel
              </Button>
              <Button
                variant="success"
                style={{ alignSelf: "flex-end" }}
                onClick={() => {
                  const {email,name,address,mobileNo,pincode,aadhar}=this.state;
                  if(name&&address&&mobileNo&&pincode&&aadhar ){
                     this.setState({ isShowWarning: true,errorMsg:"" });
                    const {
                      email,
                      name,
                      pincode,
                      address,
                      mobileNo,
                      aadhar
                    } = this.state;
                    this.props.editCustomerFunc(
                      name,
                      address,
                      mobileNo,
                      email,
                      pincode,
                      aadhar
                    );
                  }else{
                    this.setState({errorMsg:"Empty field!"})
                  }
                  
                }}
              >
                Update
              </Button>
            </div>
          </div>
        </Drawer>

        <Drawer
          open={this.state.isShowWarning}
          onRequestClose={this.toggle}
          direction="left"
          className="popup-width"
        >
          <div style={{ backgroundColor: "white", padding: 20 }}>
            <div style={{ fontSize: 18 }}>
              Do you want{" "}
              <span style={{ color: "red" }}> update the information </span>?{" "}
            </div>
            <div
              style={{
                alignSelf: "flex-end",
                textAlign: "end",
                marginTop: "40px",
                marginRight: 15,
                marginBottom: 40
              }}
            >
              <Button
                variant="danger"
                style={{ alignSelf: "flex-start", marginRight: 20 }}
                onClick={() => {
                  this.setState({ isShowWarning: false });
                }}
              >
                Cancel
              </Button>
              <Button
                variant="success"
                style={{ alignSelf: "flex-end" }}
                onClick={() => {
                  if (this.props.isOTPEnabled == "Enabled") {
                    this.setState({
                      isShowOTPDialog: true,
                     
                      isShowWarning: false,
                      
                    });
                    this.props.hindEditCustomerDialog();
                    this.sendOTPNumber()
                  } else {
                    this.setState({ isShowWarning: false });
                    const {
                      email,
                      name,
                      pincode,
                      address,
                      mobileNo,
                      aadhar
                    } = this.state;
                    this.props.editCustomerFunc(
                      name,
                      address,
                      mobileNo,
                      email,
                      pincode,
                      aadhar
                    );
                  }

                }}
              >
                ok
              </Button>
            </div>
          </div>
        </Drawer>

        <Drawer
          open={this.props.isShowEditCustomerSuccessDialog}
          onRequestClose={this.toggle}
          direction="left"
          className="popup-width"
        >
          <div style={{ backgroundColor: "white", padding: 20 }}>
            <div style={{ fontSize: 18 }}>Information updated successfully</div>
            <div
              style={{
                alignSelf: "flex-end",
                textAlign: "end",
                marginTop: "40px",
                marginRight: 15,
                marginBottom: 40
              }}
            >
              <Button
                variant="success"
                style={{ alignSelf: "flex-end" }}
                onClick={() => {
                  this.props.hindEditCustomerSuccessDialog();
                }}
              >
                ok
              </Button>
            </div>
          </div>
        </Drawer>



        <Drawer

          open={this.state.isShowOTPDialog}
          onRequestClose={this.toggle}
          direction='left'
        >

          <Card >


            <Card.Body className="pl-3 ">

              <div style={{ color: "rgb(26, 172, 195)", fontSize: 18, textAlign: "center", fontWeight: "bold", width: 300 }}>Enter OTP</div>
              {this.isShowWrongOTP()}
              <div style={{ textAlign: "center" }}>
                <input

                  style={{
                    borderBottom: "2px solid rgb(26, 172, 195)",
                    borderRight: "2px solid rgb(26, 172, 195)",
                    borderLeft: "2px solid red",
                    borderTop: "2px solid red",
                    marginTop: 20,
                    textAlign: "center",
                    height: 40,
                    width: 248
                  }}
                  value={this.state.enteredOTP}

                  type="text"
                  placeholder="Enter OTP"
                  onChange={(e) => { this.setState({ enteredOTP: e.target.value }) }}
                ></input></div>
              <Button
                style={{ backgroundColor: "red", borderColor: "red", marginLeft: 15 }}
                className="mt-3 pull-right"
                onClick={() => {
                  this.setState({ 
                    isShowOTPDialog: false,
                    randomValue:Math.floor(10000 + Math.random() * 90000),
                    email: this.props.userMetaData.Email,
      address: this.props.userMetaData.Address,
      mobileNo: this.props.userMetaData.Mobile,
      name: this.props.userMetaData.name,
                   })

                }}
              >Cancel</Button>
              <Button

                className="success" className="mt-3 pull-right"
                onClick={() => {
   this.checkVerification();

                }}
              >Verify</Button>



            </Card.Body>
          </Card>

        </Drawer>
      </div>
    );
  }
}
